import { Divider, Text, Grid, Image, Box } from "@chakra-ui/react";
import UsefulLinksButton from "./useful-links-button";

import LazyLoad from "react-lazyload";
import FadeIn from "react-fade-in";

export default function LeftImageInfo(props) {
  const textSeparator = (text) => {
    if (text !== undefined && text.includes("#")) {
      return text.split("#");
    }
    return [text];
  };
  return (
    <LazyLoad height={1000}>
      <Grid
        templateColumns={{ sm: "repeat(1, 1fr)", md: "repeat(2, 1fr)" }}
        gap={4}
      >
        <Box>
          <Image
            objectFit="cover"
            width="1300px"
            height="450px"
            src={props.image}
          ></Image>
        </Box>
        <Box>
          <Text
            textAlign="left"
            paddingBottom="2%"
            fontWeight="500"
            fontSize="30px"
          >
            {props.title}
          </Text>
          {textSeparator(props.text).map((textItem) => (
            <Text
              textAlign="left"
              paddingLeft="23px"
              paddingBottom="20px"
              fontWeight="light"
              w="100%"
            >
              {textItem}
            </Text>
          ))}
          <UsefulLinksButton />
        </Box>
      </Grid>
      <Divider
        marginTop="7%"
        marginBottom="7%"
        borderColor="gray"
        height="0.1px"
        bg="gray"
      />
    </LazyLoad>
  );
}
