import {
  AuditOutlined,
  BankOutlined,
  BookOutlined,
  EditOutlined,
  HighlightOutlined,
  TeamOutlined,
  HeartOutlined,
} from "@ant-design/icons";
import { Box, Divider, Flex, Text } from "@chakra-ui/react";
import React from "react";
import { BrowserView, isMobile } from "react-device-detect";
import ProcessBubbles from "../../Components/processes/process-bubbles";
import ProcessPanel from "../../Components/processes/process-panel";
import ProcessBar from "../../Components/processes/processes-bar";
//images
import Chatting from "../../Assets/images/Processes/design/01-Chatting-01.jpg";
import Briefing from "../../Assets/images/Processes/design/02-Briefing.jpg";
import Designing from "../../Assets/images/Processes/design/03-Designing-02.jpg";
import Detailing from "../../Assets/images/Processes/design/04-Detailing-01.jpg";
import Quoting from "../../Assets/images/Processes/design/05-Quoting-01.jpg";
import Manufacturing from "../../Assets/images/Processes/design/06-Manufacturing.jpg";
import Exciting from "../../Assets/images/Processes/design/07-Exciting-01.jpg";

export default function ProcessDesign() {
  let test = [
    {
      title: "Chatting",
      component: (
        <TeamOutlined
          className="bubble"
          style={{ fontSize: "3vw", paddingTop: "20%", color: "white" }}
        />
      ),
    },
    {
      title: "Briefing",
      component: (
        <BookOutlined
          style={{ fontSize: "3vw", paddingTop: "20%", color: "white" }}
        />
      ),
    },
    {
      title: "Designing",
      component: (
        <EditOutlined
          style={{ fontSize: "3vw", paddingTop: "20%", color: "white" }}
        />
      ),
    },
    {
      title: "Detailing",
      component: (
        <HighlightOutlined
          style={{ fontSize: "3vw", paddingTop: "20%", color: "white" }}
        />
      ),
    },
    {
      title: "Quoting",
      component: (
        <AuditOutlined
          style={{ fontSize: "3vw", paddingTop: "20%", color: "white" }}
        />
      ),
    },
    {
      title: "Manufacturing",
      component: (
        <BankOutlined
          style={{ fontSize: "3vw", paddingTop: "20%", color: "white" }}
        />
      ),
    },
    {
      title: "Exciting",
      component: (
        <HeartOutlined
          style={{ fontSize: "3vw", paddingTop: "20%", color: "white" }}
        />
      ),
    },
  ];

  const color = "gray.700";
  return (
    <>
      <Flex
        flexWrap="wrap"
        height={isMobile ? "150vh" : "90vh"}
        justifyContent="center"
      >
        <Flex flexWrap="wrap" width="100%" justifyContent="center">
          <Box width="65%">
            <Text
              textAlign="center"
              fontSize="40px"
              fontWeight="900"
              paddingTop="5%"
              paddingBottom="5%"
            >
              Design & Documentation.
            </Text>
            <Divider borderColor="black" height="1px" bg="black" />
          </Box>
        </Flex>
        <ProcessBar />
        <Flex width="80vw" justifyContent="center">
          <BrowserView>
            <ProcessBubbles test={test} color={color} width="80%" gap={10} />
          </BrowserView>
        </Flex>
      </Flex>
      <ProcessPanel
        highContrast={true}
        Image={Chatting}
        color={color}
        Title="Chatting."
        Text="                        Having found us via a personal referral or the online world we progress to having a chat on the phone.
             This gives us the opportunity to discuss your project and ask each other questions, to ensure that from both of our perspectives we believe that progressing to the next stage is the logical move.
             "
      />
      <ProcessPanel
        highContrast={false}
        Image={Briefing}
        color={color}
        Title="Briefing."
        Text="One of the
             most important aspects of the design process is to tread the dirt where the project will
             eventually be sited, so we arrange an agreeable time to meet.  Immediately after the site
             meeting is the best time to have the design brief meeting.  This meeting is all about tying
             together your needs, wants and desires with your budget and how the site will best
             accommodate your project.  To us this stage is the first “foundation”. Get this completed
             correctly and the real foundations just fall into place.  Having your project out in the
             country is not a barrier.  If you are serious, then so are we, and travelling out to meet with
             you is fine by us.
             "
      />
      <ProcessPanel
        highContrast={true}
        Image={Designing}
        color={color}
        Title="Designing."
        Text="First we put together a simple
             concept design which encapsulates the essence of your brief.  This allows you to see your
             home, your spaces and how they flow.  It’s always exciting because you see your thoughts
             down on paper for the first time and any mental gymnastics you had going on can stop,
             allowing a wonderful sense of calm and focus.  Over the next short while we refine the
             plan itself, generate the front façade and side elevations until we achieve the result which
             you feel most comprehensively achieves your goals."
      />
      <ProcessPanel
        highContrast={false}
        Image={Detailing}
        color={color}
        Title="Detailing."
        Text="The inclusions (often called
                specifications) are all the individual components that are included. If the structure is the
                macro, then the inclusions are the micro. We work through the options, which allows you
                to confirm what you included and what you want to complete yourself once you have the
                keys. We can include either model names &amp; numbers or nominated a value to cover the
                item. The latter allows you to continue researching what you want whilst other work
                continues. The result is The Pack – which contains all the information needed by a builder
                to complete a quote. Depending on our earlier discussions The Pack may include Council
                Planning consent and ancillary reports such as BAL rating analysis."
      />
      <ProcessPanel
        highContrast={true}
        Image={Quoting}
        color={color}
        Title="Quoting."
        Text="The culmination of our endeavours to
             date is sending The Pack to your nominated builder(s).  We are not brokers, because they
             add to your cost base and by definition are not at arm’s length from the builder given they
             select who gets to provide a quote.  Instead you maintain control and select the builders. 
             We are happy to offer guidance and are happy to make one recommendation.  Once the
             quotes are in we can assist you decipher the contents and sort the wheat from the chaff."
      />
      <ProcessPanel
        highContrast={false}
        Image={Manufacturing}
        color={color}
        Title="Manufacturing."
        Text="“Manufacturing” infers off-site
             construction, and “Building” infers on-site construction. The following assumes off-site
             construction.  Once the working drawings are completed and signed off by you, the builder
             calls up the orders and manufacturing begins.  Given the majority of the work occurs under
             one roof, remote from your site, the speed of construction can be breath-taking.  The
             manufacturing facility is a constant hive of activity with suppliers making drop-offs and staff
             assembling the various components.  “Go to Wow” will be measured in days or weeks, not
             months or years."
      />
      <ProcessPanel
        highContrast={true}
        Image={Exciting}
        color={color}
        Title="Exciting."
        Text="The truck(s) and mobile crane duly
             arrive onsite at the appointed hour to transfer the various pods and sections to their forever
             positions.  It’s just a matter of assembling the pods, sections and components, Single storey, double storey, in the country or in the city the process is the same - and the speed is amazing. From
             nothing onsite to everything onsite is almost instant - a couple of days to a week is all it takes. Completion of tasks which are not
             done off-site follow closely behind, so you’d better have the celebratory champers on ice."
      />
    </>
  );
}
