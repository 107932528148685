import {
    Flex, Grid, GridItem, Image, Modal, ModalBody,
    ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, useDisclosure
} from '@chakra-ui/react'
import React, { useState } from 'react'
import { isMobile } from 'react-device-detect'
import FadeIn from 'react-fade-in'
import cloudSustain from '../../Assets/images/Clouds-01.jpg'
import design from '../../Assets/images/Processes/Design-01-Drafting-Hand-01-.jpg'
import living from '../../Assets/images/Living-01.jpg'
import about from '../../Assets/images/temp-about.jpg'





export default function PictureDivider() {

    var designInfo = 
    {
        title: "Design",
        quote: "“Design is not just what it looks like and feels like. Design is how it works” - Steve Jobs.",
        text: "We listen then we create. We question then guide. We are in your hands and look forward to working with you on a new chapter in the unfolding story of your life.  ",

    }

    var aboutInfo = 
    {
        title: "About",
        text: "planet A design & living was born back in the ‘80s when I designed homes with a stick as my pen and whatever ground I happened to be on as my pad. You see, I was a river guide and expedition leader, taking people on their dream adventure holidays to many parts of the world. Taking drawing boards etc was out of the question. And laptops were not even thought of. #Fast forward a few decades and here we are, hanging out under the shingle of planet A design & living, having tucked away years of wisdom gained along the way, firstly from the many cultures I encountered as a Guide, then as an Ecologist and finally as a new homes designer. “Design” is only one part in the building equation. At planet A we offers a suite of services that enable you to have a stress-free building experience.",
        quote: "“Always render more and better service than is expected of you, no matter what your task may be.“ -  Og Mandino"

    }

    var livingInfo = 
    {
        title: "Living",
        quote: "“Life is not a dress rehearsal” - Rose Tremain",
        text: "They are a simple yet powerful set of words. Getting life and living right the first time makes a whole lot of sense. With this in mind, planet A design & living takes ecosystem approach to architectural dwelling design, be it your new home, addition, granny flat, or guest accommodation at a tourism venture. We take the time to get to know you, what you want and importantly why you want it. We consider the site and its’ surroundings, and how you want your dwelling to integrate with your urban garden or country setting. When considering the above we keep one eye on minimising disturbance at the site and how to leave an improved environment wherever possible. ",

    }

    var sustainabilityInfo = 
    {
        title: "Sustainability",
        quote: "“There must be a better way to make the things we want, a way that doesn’t spoil the sky, or the rain or the land” - Paul McCartney.",
        text: "At planet A design & living we consider sustainability as the wrap-around foundation to our ecosystem approach. Times have changed and we must change to keep up with time. To this end planet A design & living encourages our clients to adopt practices and products which tread more lightly on our planet. Sustainability is not so much about “saving the planet” as it is about “meeting our present needs without compromising the ability of future generations to meet their own needs”. By definition, when we “tread” more lightly with our practices and consumption we will not only helping our grandchildren fulfil their own needs – we are likely to be helping all the ecosystems on the planet fulfil their needs. # Because there is no planet B.",

    }
    





    const { isOpen, onOpen, onClose } = useDisclosure()

    const [popup, setPopup] = useState({})
    const textBeautifier = []
    const handleSectionClick = (info) => {
        onOpen()
        setPopup(info)
    }

    const textSeparator = (text) => {
        if(text !== undefined && text.includes('#'))
        {
            return text.split('#')
        }
        return [text];
        
    }

    return (
<>

       

        <Flex position='relative' alignItems='center' justifyContent='center'>

               <FadeIn>
            <Grid
                bg='#154734'
                padding='1%'
                borderRadius='10px'
            
                w={isMobile ? '90vw' : '70vw'}
                
                templateRows="repeat(2, 1fr)"
                templateColumns="repeat(2, 1fr)"
                gap={isMobile ? 1 : 4}
                marginTop={isMobile ? '20%' : ''}
            >
                <GridItem cursor='pointer' onClick={() => handleSectionClick(designInfo)} position='relative' className='grid-image-container' borderRadius='10px' rowSpan={1} bg='white'> 
                <Image height='100%' width='100%'  objectFit='cover'   className='grid-image' borderRadius='10px' src={design}>
                    
                </Image>
                    <Text fontSize='23px'  className='img-hover-text' color='black' position='absolute' top='50%' left='50%' transform='translate(-50%, -50%)'>
                        Design
                    </Text>
                </GridItem>
                <GridItem cursor='pointer' onClick={() => handleSectionClick(aboutInfo)}  position='relative' className='grid-image-container' borderRadius='10px' rowSpan={1} bg='white'>
                    <Image height='100%' width='100%'  objectFit='cover' className='grid-image' borderRadius='10px' src={about}>

                    </Image>
                    <Text fontSize='23px'  className='img-hover-text' color='black' position='absolute' top='50%' left='50%' transform='translate(-50%, -50%)'>
                        About
                    </Text>
                </GridItem>
                <GridItem cursor='pointer' onClick={() => handleSectionClick(sustainabilityInfo)}  position='relative' className='grid-image-container' borderRadius='10px' rowSpan={1} bg='white'>
                    <Image height='100%' width='100%'  objectFit='cover' className='grid-image' borderRadius='10px' src={cloudSustain}>
                    </Image>
                    <Text fontSize='23px' className='img-hover-text' color='black' position='absolute' top='50%' left='50%' transform='translate(-50%, -50%)'>
                        Sustainability
                    </Text>
                </GridItem>
                <GridItem cursor='pointer' onClick={() => handleSectionClick(livingInfo)}  position='relative' className='grid-image-container' borderRadius='10px' rowSpan={1} bg='white'>
                    <Image height='100%' width='100%'  objectFit='cover' className='grid-image' borderRadius='10px' src={living}>

                    </Image>
                    <Text fontSize='23px'  className='img-hover-text' color='black' position='absolute' top='50%' left='50%' transform='translate(-50%, -50%)'>
                        Living
                    </Text>
                </GridItem>
                
                
                
            </Grid>
            </FadeIn>      
        </Flex>
        <Modal  size='xl' zIndex='99999' onClose={onClose} isOpen={isOpen} isCentered>
            <ModalOverlay />
            <ModalContent textAlign='center'>
                <ModalHeader>{popup.title}</ModalHeader>
                <Text padding='30px' fontStyle='italic' fontWeight='light'>{popup.quote}</Text>
                <ModalCloseButton />
                <ModalBody pb={6}>
                    {textSeparator(popup.text).map(textItem => (
                        <Text paddingLeft='23px' paddingBottom='20px' fontWeight='light'>
                            {textItem}
                        </Text>   
                    ))}             
                </ModalBody>

                <ModalFooter>
                
                </ModalFooter>
            </ModalContent>
        </Modal>
        </>
    )
}
