import { Flex, Image, Text } from "@chakra-ui/react";
import React from "react";

export default function ProcessPanel(props) {
  const infoText = props.Text.split(".").filter((item) => item.trim() != "");
  return (
    <>
      <Flex position="relative" backgroundColor="grey" height="70vh">
        <Flex
          position="absolute"
          backgroundColor={props.highContrast ? props.color : "whiteAlpha.900"}
          width={{ base: "100%", md: "50%" }}
          height="100%"
          left={props.highContrast ? "" : "0"}
          right={props.highContrast ? "0" : "0"}
        >
          <Image src={props.Image}></Image>
        </Flex>

        <Flex
          position="relative"
          backgroundColor={props.highContrast ? props.color : "whiteAlpha.900"}
          width={{ base: "100%", md: "50%" }}
          height="auto"
          minH="80vh"
          right={props.highContrast ? "100" : "0"}
          left={props.highContrast ? "0" : "50%"}
        >
          <Flex
            //justifyContent="center"
            mx="5%"
            textAlign="left"
            direction="column"
          >
            <Text
              fontWeight="700"
              color={!props.highContrast ? "#1A202C" : "whiteAlpha.800"}
              fontSize="2.1em"
              paddingTop="50px"
              className={props.Title.replace(".", "")}
            >
              {props.Title}
            </Text>

            {infoText.map((text) => (
              <Text
                fontWeight="300"
                paddingTop="20px"
                fontSize={{ base: "0.5em", md: "1.2em" }}
                color={!props.highContrast ? "#1A202C" : "whiteAlpha.800"}
                textAlign="left"
              >
                {text}.
              </Text>
            ))}
          </Flex>
        </Flex>
      </Flex>
    </>
  );
}
