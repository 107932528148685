import { Text, Flex, ListItem, UnorderedList, Stack, Link, ListIcon, List, HStack, Grid } from '@chakra-ui/react'
import FadeIn from "react-fade-in";
import TitleHeader from '../Components/common/titleHeader'
import { AiOutlineRight } from 'react-icons/ai';



interface Props {
    postInformation: PostInformation
}

interface PostInformation {
    title: string,
    description: string,
    author: string,
    text: string
}

export default function UsefulLinks(props: Props) {


    return (

        <FadeIn transitionDuration={600}>

            <TitleHeader title='Useful links' />
            <Flex justifyContent='center' direction='column' alignItems='center' paddingBottom='200px'>
                <Grid templateColumns='repeat(3, 1fr)' pb='100px' justifyContent='space-between'>
                    <Flex w='400px' direction='column'>
                        <Text fontSize='1.5em' fontWeight='600' pb='20px'>Energy Efficiency</Text>
                        <List textAlign='left'>
                            <LinkItem title='NatHERS' link='https://www.nathers.gov.au/ ' />
                            <LinkItem title='Federal Govt. website' link='https://www.yourhome.gov.au/energy' />
                            <LinkItem title='Australian Capital Territory' link='https://www.environment.act.gov.au/energy/smarter-use-of-energy/energy-efficiency-improvement-scheme  ' />
                            <LinkItem title='New South Wales' link='https://www.planning.nsw.gov.au/News/2016/Warmer-and-more-energy-efficient-homes  ' />
                            <LinkItem title='Northern Territory' link='https://nt.gov.au/property/building/build-or-renovate-your-home/building-and-energy-efficiency   ' />
                            <LinkItem title='Queensland' link='https://www.qld.gov.au/families/government/sustainable/homes' />
                            <LinkItem title='South Australia' link='https://www.sa.gov.au/topics/energy-and-environment/energy-efficient-home-design  ' />
                            <LinkItem title='Tasmania' link='https://cbos.tas.gov.au/topics/technical-regulation/building-standards/building-practitioners/energy-efficiency ' />
                            <LinkItem title='Victoria' link='https://www.sustainability.vic.gov.au/energy-efficiency-and-reducing-emissions/building-or-renovating/' />
                            <LinkItem title='Western Australia' link='https://sustainabilitywa.com.au/ ' />


                        </List>
                    </Flex>
                    <Flex w='400px' direction='column'>
                        <Text fontSize='1.5em' fontWeight='600' pb='20px'>
                            Government Departments</Text>
                        <List textAlign='left'>
                            <LinkItem title='Federal Govt. website' link='https://www.yourhome.gov.au/energy' />
                            <LinkItem title='Australian Capital Territory' link='https://www.environment.act.gov.au/energy/smarter-use-of-energy/energy-efficiency-improvement-scheme  ' />
                            <LinkItem title='New South Wales' link='https://www.planning.nsw.gov.au/News/2016/Warmer-and-more-energy-efficient-homes  ' />
                            <LinkItem title='Northern Territory' link='https://nt.gov.au/property/building/build-or-renovate-your-home/building-and-energy-efficiency   ' />
                            <LinkItem title='Queensland' link='https://www.qld.gov.au/families/government/sustainable/homes' />
                            <LinkItem title='South Australia' link='https://www.sa.gov.au/topics/energy-and-environment/energy-efficient-home-design  ' />
                            <LinkItem title='Tasmania' link='https://cbos.tas.gov.au/topics/technical-regulation/building-standards/building-practitioners/energy-efficiency ' />
                            <LinkItem title='Victoria' link='https://www.sustainability.vic.gov.au/energy-efficiency-and-reducing-emissions/building-or-renovating/' />
                            <LinkItem title='Western Australia' link='https://sustainabilitywa.com.au/ ' />


                        </List>
                    </Flex>



                    <Flex w='400px' direction='column'>
                        <Text fontSize='1.5em' fontWeight='600' pb='20px'>Administrative Tribunals</Text>
                        <List textAlign='left' pb='50px'>
                            <LinkItem title='Australian Capital Territory' link='https://www.acat.act.gov.au/ ' />
                            <LinkItem title='New South Wales' link='https://www.ncat.nsw.gov.au/' />
                            <LinkItem title='Northern Territory' link='https://ntcat.nt.gov.au/  ' />
                            <LinkItem title='Queensland' link='https://www.qcat.qld.gov.au/  ' />
                            <LinkItem title='South Australia' link='http://www.sacat.sa.gov.au/  ' />
                            <LinkItem title='Tasmania' link='https://www.google.com/search?q=TasCat&sxsrf=AOaemvLDS0v0Gs-oevzVSAdxfRafy-mrWg%3A1631615455639&source=hp&ei=33lAYZmKJJbE0PEPsd29-AI&iflsig=ALs-wAMAAAAAYUCH74SrWzGbGc6EG8I9h4v2Tl7t3XFH&oq=TasCat&gs_lcp=Cgdnd3Mtd2l6EAMyBAgAEEMyBwgAELEDEEMyCgguEMcBENEDEEMyBAgAEEMyBwgAELEDEEMyCwguEIAEEMcBENEDMgsILhCABBDHARCvATIFCAAQgAQyBQgAEIAEMgUIABCABDoHCCMQ6gIQJzoECCMQJzoFCAAQkQI6CggAELEDEIMBEEM6DgguEIAEELEDEMcBEKMCOhEILhCABBCxAxCDARDHARDRAzoHCAAQyQMQQzoECC4QQzoHCC4QsQMQQ1CzC1jqFGDlFWgBcAB4AIABnAKIAbwHkgEDMi00mAEAoAEBsAEK&sclient=gws-wiz&ved=0ahUKEwjZ9Y-dof7yAhUWIjQIHbFuDy8Q4dUDCAk&uact=5 ' />
                            <LinkItem title='Victoria' link='https://www.vcat.vic.gov.au/  ' />
                            <LinkItem title='Western Australia' link='https://www.sat.justice.wa.gov.au/  ' />


                        </List>
                    </Flex>
                    <Flex w='400px' direction='column'>
                        <Text fontSize='1.5em' fontWeight='600' pb='20px'>Bushfire Information</Text>
                        <List textAlign='left' pb='50px'>
                            <LinkItem title='Development in Australian bushfire
prone areas' link='https://www.architecture.com.au/wp-content/uploads/Development-in-Australian-bushfire-prone-areas-Australian-Institute-of-Architects.pdf ' />
                            <LinkItem title='Site planning and design for bushfire' link='https://www.architecture.com.au/wp-content/uploads/Acumen_Site-planning-and-design-for-bushfire_Australian-Institute-of-Architects.pdf' />
                            <LinkItem title='Development in Australian bushfire prone areas' link='https://acumen.architecture.com.au/environment/place/climate/development-in-australian-bushfire-prone-areas/' />



                        </List>
                    </Flex>

                </Grid>
            </Flex>

        </FadeIn>


    )

}

interface LinkItemProps {
    title: string,
    link: string
}

const LinkItem = (props: LinkItemProps) => {
    return (
        <Link href={props.link} isExternal>
            <ListItem cursor='pointer'>
                <HStack>
                    <ListIcon as={AiOutlineRight} /><Text textDecoration='underline' >{props.title}</Text>
                </HStack>
            </ListItem>
        </Link>

    )
}