import { useMutation } from '@apollo/client';
import { Button, Input, Text, VStack } from '@chakra-ui/react';
import { onError } from 'apollo-link-error';
import React, { useCallback, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { CREATE_BLOG_POST } from '../queries/posts';

interface Props {
    postInformation: PostInformation
}

interface PostInformation {
    title: string,
    description: string,
    author: string,
    text: string
}

export default function CreatePost(props: Props) {

    const link = onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors)
          graphQLErrors.forEach(({ message, locations, path }) =>
            console.log(
              `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
            )
          );
        if (networkError) console.log(`[Network error]: ${networkError}`);
      });

    const [PostInformation, setPostInformation] = useState<PostInformation>({
        title: "",
        description: "",
        author: "",
        text: "",
    })

    const [addPost, {data, error}] = useMutation(CREATE_BLOG_POST)

    
    const getQuillDelta = useCallback((value, delta, source, editor) => {
        console.log(`Changing Text's value to ${value}`)

        setPostInformation({
            ...PostInformation,
            text: value
        })
    }, [])

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        console.log(`Changing ${event.target.name}'s value to ${value}`)
        setPostInformation({
            ...PostInformation,
            [event.target.name]: value
        })
    }

    const handleSubmit = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault();
        var outcome =  addPost({variables: PostInformation})

        if(error) return error;
    }

    return (
        <section className='container'>
            <Button
					colorScheme="green"
					marginTop="30px"
					width="100px"
					onClick={handleSubmit}
				>
					Create Post
				</Button>
            <VStack
            paddingTop='50px'
				// divider={<StackDivider borderColor="gray.600" />}
				spacing={4}
				align="stretch"
                width='50vw'
                alignContent='center'
                margin='0 auto'

			>
                <Text mb="8px">Title:</Text>
                <Input
                    name='title'
                    value={PostInformation.title}
                    onChange={handleChange}
                    placeholder="Title"
                    size="sm"
                />
            
                <Text mb="8px">Description:</Text>
                <Input
                    name='description'
                    value={PostInformation.description}
                    onChange={handleChange}
                    placeholder="Description"
                    size="sm"
                />
                <Text mb="8px">Author:</Text>
                <Input
                    name='author'
                    value={PostInformation.author}
                    onChange={handleChange}
                    placeholder="Author"
                    size="sm"
                />
               
            </VStack>
            <main className='post-editor'>
                <ReactQuill value={PostInformation.text}  onChange={getQuillDelta}/>
            </main>
            
            
        </section>
    )
}