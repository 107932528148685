import React from 'react'
import {Link} from 'react-router-dom'
import {TagRow} from './'

export default function MasonryPost ({post, tagsOnTop}) {
    const windowWidth = window.innerWidth
    const imageBackground = {backgroundImage:`url("${require(`../../Assets/images/${post.image}`).default}")`}

    const style = windowWidth > 900 ?  {...imageBackground, ...post.style} : {imageBackground}

    return (
        
        <a className="masonry-post overlay" style={style} href={post.link}>
            <div className="image-text" style={{justifyContent: tagsOnTop ? 'space-between' : 'flex-end'}}>
                <TagRow tags={post.categories}></TagRow>
                <div>
                    <Link to={`/post/${post.id}`}>
                        <h2 className="image-title">{post.title}</h2>
                    </Link>
                    <span className="image-date">{post.date}</span>
                </div>
            </div>
        </a>
        
    )
}