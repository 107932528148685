import { Grid, GridItem, Text } from '@chakra-ui/react'
import React from 'react'
import { Link } from 'react-router-dom'
import '../../Assets/scss/processes.scss'

export default function OptionsBar(props) {

    return (
        <>
        
        <Grid height='0vh' paddingTop='5%' width='70%' templateColumns={{ base: "repeat(1, 1fr)" , md: "repeat(2, 1fr)"  }} gap={20}>
            <Link  to='/process/design'>
            <GridItem 
            backgroundSize='cover' 
            backgroundImage="linear-gradient(180deg, rgba(255,0,0,0) 30% , black 120%), url('/Design-01-Drafting-Hand-01-.jpg')"  
            //boxShadow="dark-lg" 
            p="6" rounded="md"  
            borderRadius='10' 
            paddingTop='15%' 
            textAlign='center' 
            w="100%" 
            h="200px"  
            cursor='pointer'
            
        
            >
                <Text fontWeight='700' fontSize='25px' color='#e2e8f0'>Design Services</Text>
            </GridItem>
            </Link>
            <Link to='/process/management'>
            <GridItem 
            backgroundSize='cover' 
            
            backgroundImage="linear-gradient(180deg, rgba(255,0,0,0) 30% , black 120%), url('/home-2.JPG')"  
            //boxShadow="dark-lg" 
            p="6" rounded="md"  
            borderRadius='10' 
            paddingTop='15%' 
            textAlign='center' 
            w="100%" 
            
            h="200px"  
            cursor='pointer'

        
            >
                <Text textDecoration='none' fontWeight='700' fontSize='25px' color='#e2e8f0'>Management Services</Text>
            </GridItem>
            </Link>
          
            
        </Grid>
        </>
    )
}
