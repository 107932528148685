import React from 'react';
import { useParams } from 'react-router-dom';
import HouseDesignFeature from '../Components/housedesigns/HouseDesignFeature';
import OptionTabs from '../Components/housedesigns/options-tabs';



export default function HomeDesigns() {

    const backyardItems = [
        'The Guest Studio',
        'The Mini Haus Mk1',
        'The Mini Haus Mk2',
        'The Small Haus',
    
    ]

    const strataSubItems = [
        'Plan A',
        'Plan B',
        'Plan C',
    
    ]

    const smallLotItems = [
        'Plan A',
        'Plan B',
        'Plan C',
    
    ]

    const customItems = [
        'The Guest Studio',
        'The Mini Haus Mk1',
        'The Mini Haus Mk2',
        'The Small Haus',
    
    ]

    var homeType = useParams();
    console.log(homeType)
    switch (homeType.type){
        case 'backyard':
            return (
            <>
            <OptionTabs 
            range={ 
                <HouseDesignFeature  title='The Haus Range' description='backyard description' headings={backyardItems} image=''/>
                }/>
            </>)
        case 'stratasub':
        return <HouseDesignFeature   title='Strata Sub ' description='strata sub description' headings={strataSubItems} image=''/>     
        case 'smalllot':
            return <HouseDesignFeature   title='Small Lot' description='Small lot description' headings={smallLotItems} image=''/>     
        case 'custom':
            return <HouseDesignFeature   title='Custom' description='Custom description' headings={backyardItems} image='' />     
        default:
            return 'hmmmm something went wrong'


    }
    
}