import React from 'react'
import {Box, AspectRatio, Container, Flex, Text, VStack, StackDivider, Button} from '@chakra-ui/react'
import PropTypes from "prop-types"

const InformationBox = ({Title, Description, ButtonText, ButtonAction, Orientation}) => {
    const isLeft = Orientation === 'left'
        return (
            <Flex paddingBottom='15%' width='100vw' justifyContent={isLeft ? 'flex-start' : 'flex-end'}  >
                <Box 
                shadow='2xl'  
                position='relative' 
                alignItems='flex-end' 
                bg='#154734' 
                width={{base:'100vw', md: '70vw'}} 
                height='300px' 
                borderRadius={{base: 0, md: isLeft ? '0px 15px 15px 0px' : '15px 0px 0px 15px'}}>
                    <Text fontSize='2xl' paddingLeft='45px' paddingTop='30px'>{Title}</Text>
                    <Text fontWeight='light' fontSize='md' paddingLeft='45px' paddingTop='30px'>{Description}</Text>

                    <div style={{paddingLeft: '75%', paddingTop: '100px'}}>
                        <Button  colorScheme='whiteAlpha'>{ButtonText}</Button>
                    </div>
                </Box>
            </Flex>
        )
    
}

InformationBox.propTypes = {
    Title: PropTypes.string,
    Description: PropTypes.string,
    ButtonText: PropTypes.string,
    ButtonAction: PropTypes.string,
    Orientation: PropTypes.string
  }
   
  InformationBox.defaultProps = {
    Title: "What is planet A designs & living?",
    Description:
      "We are a blah blah blah stuff houses etc",
    ButtonText: "Find Out More",
    ButtonAction: '/about',
    Orientation: 'left'
    
  }

export default InformationBox