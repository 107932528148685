import {
  BookOutlined,
  EditOutlined,
  HighlightOutlined,
  TeamOutlined,
} from "@ant-design/icons";
import { Box, Divider, Flex, Text } from "@chakra-ui/react";
import React from "react";
import { BrowserView, isMobile } from "react-device-detect";
import ProcessBubbles from "../../Components/processes/process-bubbles";
import ProcessPanel from "../../Components/processes/process-panel";
import ProcessBar from "../../Components/processes/processes-bar";

//images
import Quote from "../../Assets/images/Processes/management/07-TheQuote-03.png";
import Contract from "../../Assets/images/Processes/management/08-TheContract-01.jpg";
import Build from "../../Assets/images/Processes/management/09-TheBuild-02a.png";
import Handover from "../../Assets/images/Processes/management/10-TheHandover-01aHouse-Passiv.png";

export default function ProcessQuoteManagement() {
  let test = [
    {
      title: "The Quote",
      component: (
        <TeamOutlined
          style={{ fontSize: "3vw", paddingTop: "20%", color: "white" }}
        />
      ),
    },
    {
      title: "The Contract",
      component: (
        <BookOutlined
          style={{ fontSize: "3vw", paddingTop: "20%", color: "white" }}
        />
      ),
    },
    {
      title: "The Build",
      component: (
        <EditOutlined
          style={{ fontSize: "3vw", paddingTop: "20%", color: "white" }}
        />
      ),
    },
    {
      title: "The Handover",
      component: (
        <HighlightOutlined
          style={{ fontSize: "3vw", paddingTop: "20%", color: "white" }}
        />
      ),
    },
  ];

  const color = "gray.700";
  return (
    <>
      <Flex
        flexWrap="wrap"
        height={isMobile ? "150vh" : "90vh"}
        justifyContent="center"
      >
        <Flex flexWrap="wrap" width="100%" justifyContent="center">
          <Box width="65%">
            <Text
              textAlign="center"
              fontSize="40px"
              fontWeight="900"
              paddingTop="5%"
              paddingBottom="5%"
            >
              Quote & Project Management.
            </Text>
            <Divider borderColor="black" height="1px" bg="black" />
          </Box>
        </Flex>
        <ProcessBar />
        <Flex justifyContent="center">
          <BrowserView>
            <ProcessBubbles test={test} color={color} width="100%" />
          </BrowserView>
        </Flex>
      </Flex>
      <ProcessPanel
        highContrast={true}
        Image={Quote}
        color={color}
        Title="The Quote."
        Text="In the
             beginning one tend to think in terms of cost, however decades of experience has taught us
             that people remember a their “client” experience, build quality long and level of finish long
             after the cost is forgotten.  We have designed an analysis process which gives you an
             unbiased report into each quote so that you can make an informed decision about the
             builder you should engage further with.  We offer this service to anyone, regardless of
             whether we have been involved in any design or other work earlier."
      />
      <ProcessPanel
        highContrast={false}
        Image={Contract}
        color={color}
        Title="The Contract."
        Text="A simple document, yet complex to
             understand, the contract is the formal agreement between you and your chosen builder
             which sets out the roles and responsibilities, the commitments and rectification
             mechanisms. You will have questions. You can ask your chosen builder, but they have a
             vested interest. Or you can ask us and receive plain english explanations. We offer this
             service to anyone, regardless of whether we have been involved in any design or other work
             earlier."
      />
      <ProcessPanel
        highContrast={true}
        Image={Build}
        color={color}
        Title="The Build."
        Text="We are all busy people these days. If you are unable to visit site or the manufacturing facility on a regular basis or would like somebody “in the know” to accompany you when you do arrive, then look no further. We can arrange to meet regularly or on an ad hoc basis. We offer this service to anyone, regardless of whether we have been involved in any design or other work earlier. "
      />
      <ProcessPanel
        highContrast={false}
        Image={Handover}
        color={color}
        Title="The Handover."
        Text="All builders complete a “Practical Completion Inspection” (commonly called PCI). This occurs once all the works are practically complete and is the first (and often the only) opportunity for you to walk through, inspect and inform the builder of all that concerns you about your home and the workmanship on it. Do you know what to look for? Do you know what is correct and what is not? If you answered no to either of these, then invite us to accompany you to this all important meeting. We offer this service to anyone, regardless of whether we have been involved in any design or other work earlier."
      />
    </>
  );
}
