import { Divider, Text, Grid, Image } from "@chakra-ui/react";

import FadeIn from "react-fade-in";

export default function ThreeImageInfo(props) {
  const textSeparator = (text) => {
    if (text !== undefined && text.includes("#")) {
      return text.split("#");
    }
    return [text];
  };
  return (
    <FadeIn>
      <Text
        textAlign="left"
        paddingTop="5%"
        paddingBottom="2%"
        fontWeight="500"
        fontSize="30px"
      >
        {props.title}
      </Text>
      {textSeparator(props.text).map((textItem) => (
        <Text
          textAlign="left"
          paddingLeft="23px"
          paddingBottom="20px"
          fontWeight="light"
        >
          {textItem}
        </Text>
      ))}
      <Grid
        templateColumns={{ sm: "repeat(1, 1fr)", md: "repeat(3, 1fr)" }}
        gap={4}
      >
        <Image shadow="lg" margin="5px" src={props.image1} W="200px"></Image>
        <Image shadow="lg" margin="5px" src={props.image2} W="200px"></Image>
        <Image shadow="lg" margin="5px" src={props.image3} W="200px"></Image>
      </Grid>
      <Divider
        marginTop="7%"
        marginBottom="7%"
        borderColor="gray"
        height="0.1px"
        bg="gray"
      />
    </FadeIn>
  );
}
