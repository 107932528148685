import { Button } from "@chakra-ui/button";
import { Link } from 'react-router-dom'




export default function UsefulLinksButton() {


    return (
        <Link to='/useful-links'>
            <Button textDecoration='none' colorScheme='teal' color='white' backgroundColor='#154734'>Useful Links</Button>
        </Link>
    )
}