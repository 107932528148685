import { Tab, TabList, Tabs } from '@chakra-ui/react'
import React from 'react'

export default function OptionsTabs(props) {

    switch (props.type){
        case 'backyard':
            return (
                <>
                <Tabs  isFitted alignContent='center' alignItems='center' bg='whiteAlpha.700' colorScheme="teal">
                <TabList >
                    <Tab>The Haus Range</Tab>
                    <Tab isDisabled>The Pod Range</Tab>
                    <Tab isDisabled>The Compact Range</Tab>
                </TabList>
                {/* <TabPanels>
                    <TabPanel>
                    </TabPanel>
                    <TabPanel>
                    </TabPanel>
                </TabPanels> */}
                </Tabs>
                {props.range}
                </>
            ) 
        default:
            return (
                <>
                <Tabs position='sticky'  isFitted alignContent='center' alignItems='center' bg='whiteAlpha.700' colorScheme="teal">
                <TabList >
                    <Tab>The Haus Range</Tab>
                    <Tab isDisabled>The Pod Range</Tab>
                    <Tab isDisabled>The Compact Range</Tab>
                </TabList>
                {/* <TabPanels>
                    <TabPanel>
                    </TabPanel>
                    <TabPanel>
                    </TabPanel>
                </TabPanels> */}
                </Tabs>
                {props.range}
                </>
            )  
    }  
    
}
