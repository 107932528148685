import {
  Box,
  Button,
  Divider,
  Flex,
  Image,
  Stack,
  Text,
} from "@chakra-ui/react";
import React from "react";
import FadeIn from "react-fade-in";
import LazyLoad from "react-lazyload";
import { Link } from "react-router-dom";
import ProjectManagement from "../../Assets/images/Management/ProjectManagement-01.jpg";
import QuotationManagement from "../../Assets/images/Management/QuotationManagement-01.jpg";
import QuotationManagement2 from "../../Assets/images/Management/QuotationManagement-02.jpg";
import SubdivisionManagement from "../../Assets/images/Management/SubdivisionManagemnt-01.jpg";
import "../../Assets/scss/processes.scss";

import TitleHeader from "../../Components/common/titleHeader";

export default function ManagementServices() {
  return (
    <FadeIn transitionDuration="600">
      <>
        <Flex textAlign="center" flexWrap="wrap" justifyContent="center">
          <Box width="65%" paddingBottom="0%">
            <FadeIn transitionDuration="6as00">
              <Text
                textAlign="center"
                fontSize="40px"
                fontWeight="900"
                paddingTop="5%"
                paddingBottom="5%"
              >
                Management Services.
              </Text>

              <Divider borderColor="black" height="1px" bg="black" />
            </FadeIn>
          </Box>
          <Stack
            wrap="wrap"
            textAlign="center"
            width="70%"
            justifyContent="center"
          >
            <FadeIn>
              <Text
                paddingTop="5%"
                paddingBottom="2%"
                fontWeight="500"
                fontSize="30px"
              >
                Quotation Management
              </Text>
              <Text>
                We have over 20 years’ experience in all types of residential
                construction, including double clay brick, brick veneer, timber
                and steel frame construction. Our Quotation Management service
                provides you with a report outlining the good, the bad and the
                ugly contained within the quotes your selected builders have
                provided. Supply your quotes to us for a comprehensive and
                independent analysis and report. <br></br> This service is
                available to clients independently of our design services.{" "}
              </Text>

              <Flex
                justifyContent="space-around"
                paddingTop="5%"
                paddingBottom="5%"
              >
                <Image
                  objectFit="cover"
                  width="800px"
                  height="400px"
                  alignSelf="center"
                  src={QuotationManagement}
                ></Image>
                <Image
                  objectFit="cover"
                  width="800px"
                  height="400px"
                  alignSelf="center"
                  src={QuotationManagement2}
                ></Image>
              </Flex>
              {/* Button should go to processes  */}
              <Link to="/processes">
                <Button
                  width="200px"
                  marginBottom="2%"
                  colorScheme="teal"
                  variant="outline"
                >
                  Explore
                </Button>
              </Link>
              <Divider
                marginTop="7%"
                marginBottom="7%"
                borderColor="gray"
                height="0.1px"
                bg="gray"
              />
            </FadeIn>

            <LazyLoad height={1000}>
              <Flex
                textAlign="center"
                wrap="wrap"
                justifyContent="space-between"
                paddingBottom="30px"
                dir={{ base: "column", md: "row" }}
              >
                <Flex
                  textAlign="center"
                  width={{ base: "100%", md: "50%" }}
                  wrap="wrap"
                  justifyContent="center"
                >
                  <Text paddingBottom="5%" fontWeight="500" fontSize="30px">
                    Project Management
                  </Text>
                  <Text width="90%">
                    Our Project Management service provides you with ongoing
                    support during the Pre-construction phase and the
                    Construction phase of your new build. We find that builders’
                    clients are often not well informed about their rights or
                    the responsibilities the builder has, when it comes to
                    construction. You can select to have our involvement and
                    guidance during the early stages, between accepting a
                    quotation and action on site, and/or during the on-site
                    construction period of your contract. <br></br> This service
                    is available to clients independently of our design
                    services.
                  </Text>
                </Flex>
                <Flex paddingTop="5%" width={{ base: "100%", md: "50%" }}>
                  <Image
                    objectFit="cover"
                    width="1000px"
                    height="400px"
                    alignSelf="center"
                    src={ProjectManagement}
                  ></Image>
                </Flex>
              </Flex>
              {/* Button should go to processes  */}
              <Link to="/processes">
                <Button
                  width="200px"
                  marginBottom="2%"
                  colorScheme="teal"
                  variant="outline"
                >
                  Explore
                </Button>
              </Link>
              <Divider
                marginTop="7%"
                marginBottom="7%"
                borderColor="gray"
                height="0.1px"
                bg="gray"
              />
            </LazyLoad>

            <LazyLoad height={500}>
              <Flex
                textAlign="center"
                wrap="wrap"
                justifyContent="space-between"
                paddingBottom="30px"
                dir={{ base: "column", md: "row" }}
              >
                <Flex width={{ base: "100%", md: "50%" }}>
                  <Image
                    objectFit="cover"
                    width="1000px"
                    height="400px"
                    alignSelf="center"
                    src={SubdivisionManagement}
                  ></Image>
                </Flex>
                <Flex
                  paddingTop="5%"
                  textAlign="center"
                  width={{ base: "100%", md: "50%" }}
                  wrap="wrap"
                  justifyContent="center"
                >
                  <Text paddingBottom="2%" fontWeight="500" fontSize="30px">
                    Sub-division Management
                  </Text>
                  <Text marginLeft="30px">
                    Our Sub-division Management service makes subdividing your
                    block a breeze. Surveyors are great at what they do, however
                    they do not consider house design and construction
                    requirements when dividing the land. We do and that
                    knowledge can provide a far better outcome for you.
                    <br></br>
                    <br></br>Step 1 involves having the surveyors complete the
                    Contour and Features survey, followed by us working with you
                    to ensure that your proposed boundaries and new lot sizes
                    make the most of the available land. We may consult with the
                    local Council Planners as part of this stage. We provide the
                    proposed new lot information to the Surveyors and the
                    sub-division application is submitted to the State Planning
                    Authority for ratification.
                    <br></br>
                    <br></br>Step 2 involved us guiding you through compliance
                    stage. After the approval letter is received we complete
                    online applications to infrastructure providers and
                    arranging for quotations for all works required on site. The
                    result is all conditions set by the State Planning Authority
                    are satisfied and you can proceed to having new Certificates
                    of Title issued.
                    <br></br> This service is available to clients independently
                    of our design services.
                  </Text>
                </Flex>
              </Flex>
              {/* Button should go to processes  */}
              <Link to="/processes">
                <Button
                  width="200px"
                  marginBottom="2%"
                  colorScheme="teal"
                  variant="outline"
                >
                  Explore
                </Button>
              </Link>
            </LazyLoad>
          </Stack>
        </Flex>
      </>
    </FadeIn>
  );
}
