import React, {useState} from 'react';
import { UpCircleOutlined } from '@ant-design/icons'
import '../../Assets/scss/scrollUp.scss'

const ScrollUp= () =>{

  const [showScroll, setShowScroll] = useState(false)

  const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset > window.innerHeight){
      setShowScroll(true)
    } else if (showScroll && window.pageYOffset <= window.innerHeight){
      setShowScroll(false)
    }
  };

  const scrollTop = () =>{
    window.scrollTo({top: 0, behavior: 'smooth'});
  };

  window.addEventListener('scroll', checkScrollTop)

  return (
      <>
        <UpCircleOutlined className="scrollTop" onClick={scrollTop} style={{ 
            position: 'fixed', 
            bottom: '50px', 
            right: '0',
            marginRight: '50px',
            opacity: '0.5',
            fontSize: '80px', 
            display: showScroll ? 'flex' : 'none', 
            zIndex: '9999999', 
            }}/>
    </ >
  );
}

export default ScrollUp;