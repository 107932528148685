import { Box, Button, Image, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, useDisclosure, VStack } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap'
import { MobileView } from 'react-device-detect'
import Scroll from 'react-scroll'
import house1 from '../../Assets/images/house1.jpg'

interface Props {
    title: string,
    description: string,
    image: string, 
    headings: Array<string>

}

interface State {
    currentlyPicked: string
}


var ScrollDown = Scroll.Link
var scroll = Scroll.scroller

export default function HouseDesignFeature(props: Props) {


    




    const [state, setstate] = useState<State>({
        currentlyPicked: props.headings[0]
    })

    useEffect(() => {
        //window.onload(() => window.scrollTo(0, 0))
    
        // console.log(props)
        // const getNext = props.headings[props.headings.indexOf(state.currentlyPicked)+1]
        // window.addEventListener("wheel", event => ScrollToForWheel(`chakra-text ${getNext} css-x1jvnz`, event));
        // setstate({
        //     ...state,
        //     currentlyPicked: getNext
        // })
        // console.log(state.currentlyPicked)
      }, [])

    const ScrollTo = (element: string, e: React.MouseEvent<HTMLParagraphElement, MouseEvent>) => {
        scroll.scrollTo(element, {
            duration: 1000,
            smooth: true,
            offset: -100
        })
        const targ = e.target as HTMLElement
        setstate({
            ...state,
            currentlyPicked: targ.innerText
        })
    }

    const ScrollToForWheel = (element: string, e: WheelEvent) => {
        scroll.scrollTo(element, {
            duration: 1000,
            smooth: true,
            offset: -100
        })
        const targ = e.target as HTMLElement
        setstate({
            ...state,
            currentlyPicked: targ.innerText
        })
    }

    const handleMouseOver = (e: React.MouseEventHandler<HTMLParagraphElement>) => {
        console.log(e)
    }
      
    const { isOpen, onOpen, onClose } = useDisclosure()

   
    return (
        <>
        
        <Container >
            
            <Box visibility={{base: 'hidden', md: 'visible'}} zIndex='100' paddingTop='50px'  bg='white' opacity='70%' width='15%' height='100vh' position='fixed'  left='0' textAlign='center' >
                <Text width='100%' paddingTop='50px' fontSize='22px' >{props.title}</Text>
                <VStack
                paddingTop='55%'
                //divider={<StackDivider borderColor="black" width='70%'/>}
                spacing={100}
                align="stretch"
                >
                    {props.headings.map(item => (
                        <Text as={state.currentlyPicked == item ? 'u' : 'i'} onMouseOverCapture={() => handleMouseOver} cursor='pointer' fontWeight='390' onClick={(e) => ScrollTo(`chakra-text ${item} css-x1jvnz`, e)}>
                            {item}
                        </Text>
                        
                    ))}
                   
                </VStack>
            </Box>

            {/* <Box top='auto' className='container' marginLeft='20%'  overflow='hidden'> */}
        
                
                <VStack left={{base:'0', md: '15%'}} width={{base: '100%', md: '70%'}} position='absolute'  >
                {props.headings.map((item) => (
                    
                    <Box >
                        <Text width='100%' textAlign='center' position='absolute' paddingTop='30px' color='grey.200'  opacity='0.8' fontSize={{base:'20px', md: '25px' }}className={item}>
                            {item}
                        </Text>

                        <Image  display='block'  src={house1} minHeight={{base: '40vh', md:'93vh' }} maxH='90vh' width='90vw'></Image>

                        
                        {/* WARNING: changing this css for text will break the auto scroll  */}
                        <MobileView>
                            <Box height='50vh'>
                                <Text >About the thing</Text>

                            </Box>
                            
                        </MobileView>
                        
                    </Box>
                   
                    ))}
                </VStack>

            <Modal onClose={onClose} isOpen={isOpen} isCentered autoFocus={false}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Modal Title</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        
                    </ModalBody>
                    <ModalFooter>
                    <Button onClick={onClose}>Close</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Container>
        </>
    )
}
