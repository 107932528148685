import {
  Box,
  Divider,
  Flex,
  Image,
  Stack,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { isMobile } from "react-device-detect";
import FadeIn from "react-fade-in";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader


interface Props {
  title: string,
  items: ItemList[]
  info: string

}

interface ItemList {
  itemName: string,
  image: string,
  pdf: string
}

export default function DesignSet(props: Props) {
  return (
    <>
      <Flex
        height="auto"
        color='black'
        width="100%"
        wrap={isMobile ? "wrap" : "nowrap"}
        justifyContent="space-evenly"
        minHeight="600px"
        alignItems="center"
        pt='50px'
      >
        <Stack
          marginLeft={{ md: "15%", base: "" }}
          textAlign={{ md: "left", base: "center" }}
          width={{ md: "30%", base: "100%" }}
          paddingTop={isMobile ? "40px" : ""}
        >
          <Text
            paddingBottom="2%"
            fontSize={{ md: "35px", base: "20px" }}
            fontWeight="700"
          >
            {props.title}
          </Text>
          <Text paddingBottom="2%"
            fontSize='15px'
            fontWeight="600">{props.info}</Text>
          {props.items.map((item) => {
            return (

              <Text fontWeight="200" paddingBottom="2%">
                <a href={item.pdf} rel="noreferrer" target="_blank">
                  {item.itemName}
                </a>
              </Text>
            )
          })}
        </Stack>

        <Carousel
          width={isMobile ? "100%" : "40vw"}
          showArrows={true}
          autoPlay={true}
          infiniteLoop={true}
          interval={9000}
          transitionTime={1500}
          showIndicators={false}
          showStatus={false}
        >

          {props.items.map((item) => {
            return (
              <Box>
                {item.image && <Image src={item.image} size="50%" />}
                {item.image && <Text fontWeight="800">{item.itemName}</Text>}
                {!item.image && <Text>Coming Soon...</Text>}
              </Box>
            )
          })}
        </Carousel>
      </Flex>
      <Divider w="80%" borderColor="black" height="0.5px" bg="gray.300" />
    </>
  );
}
