import Navigation from "./Components/navigation";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import Blog from "./pages/blog";
import Login from "./pages/login";
import Tourism from "./pages/tourism";
import EditPost from "./pages/edit-post";
import CreatePost from "./pages/create-post";
import HomeDesigns from "./pages/home-designs";
import Inspirations from "./pages/inspirations";
import BeingInformed from "./pages/information";
import Benefits from "./pages/benefits";
import faq from "./pages/faq";
import ViewPost from "./pages/blog-view";
import AboutUs from "./pages/aboutus";
import Home2 from "./pages/home2";
import ProcessDesign from "./pages/Processes/process-design";
import Processes from "./pages/Processes/processes";
import ProcessQuoteManagement from "./pages/Processes/process-management";
import ProcessSubDivManagement from "./pages/Processes/process-management-subdiv";
import Why from "./pages/why";
import OptionsHome from "./pages/YourOptions/options-home";
import DesignServices from "./pages/YourOptions/design-services";
import ManagementServices from "./pages/YourOptions/management-services";
import ScrollToTop from "./Components/PageScroll/TopOfPage";
import ScrollUp from "./Components/common/scrollUp";

import { Footer } from "./Components/common";
import UsefulLinks from "./pages/usefull-links";
import Terms from "./pages/terms-conditions";
import Privacy from "./pages/privacy-policy";

function App() {
  return (
    <Router>
      <ScrollToTop />

      <Navigation />
      <ScrollUp />

      <Switch>
        <Route path="/useful-links" component={UsefulLinks} />
        <Route path="/privacy-policy" component={Privacy} />
        <Route path="/termsandconditions" component={Terms} />

        <Route path="/blog" component={Blog} />
        <Route path="/login" component={Login} />
        <Route path="edit/post/:id" component={EditPost} />
        <Route path="/post/newpost" component={CreatePost} />
        <Route path="/post/:id" component={ViewPost} />
        <Route path="/edit-post" component={EditPost} />
        <Route path="/home-designs/:type" component={HomeDesigns} />
        <Route path="/eco-tourism" component={Tourism} />
        <Route path="/benefits" component={Benefits} />
        <Route path="/faq" component={faq} />
        <Route path="/inspiration" component={Inspirations} />
        <Route path="/informed" component={BeingInformed} />
        <Route path="/processes" component={Processes} />
        <Route path="/about-us" component={AboutUs} />
        <Route path="/why" component={Why} />
        <Route path="processes" component={Processes} />
        <Route path="/process/design" component={ProcessDesign} />
        <Route path="/process/management" component={ProcessQuoteManagement} />
        <Route
          path="/process/management-sub"
          component={ProcessSubDivManagement}
        />
        <Route path="/your-options" component={OptionsHome} />

        <Route path="/design-services" component={DesignServices} />

        <Route path="/management-services" component={ManagementServices} />

        <Route path="/" component={Home2} />

        <Route component={() => 404} />
      </Switch>
      <Footer />
    </Router>
  );
}

export default App;
