import { useMutation, useQuery } from '@apollo/client';
import React from 'react';
import { useParams } from 'react-router-dom';
import { CREATE_BLOG_POST, GET_POST } from '../queries/posts';

const BlogPage = () => {

    const [addPost] = useMutation(CREATE_BLOG_POST)

    const {id} = useParams();
    const idAsInt = parseInt(id)

    const {data, error, loading} = useQuery(GET_POST, {
        variables: {
            id: idAsInt
        }
    })


   

    if(loading) return 'Loading...'
    if(error) return `Error blah ${error.message}`

    if(data) console.log(data.post)

    return (
        <main className='post-editor'>
            <section className='title-container'>
                <h1>{data.post.title}</h1>
                <p>{data.post.date}</p>
            </section>

            <section>
                <p>{data.post.text}</p>
            </section>
        </main>
    )
}

export default BlogPage;