import { useQuery } from '@apollo/react-hooks';
import React from 'react';
import { PostMasonry } from '../Components/common/index';
import { GET_POSTS_BY_CATEGORIES_QUERY } from '../queries/posts';


const trendingConfig = {
    1: {
        gridArea: '1 / 2 / 3 / 3'

    }
}



const mergeStyles = function (posts, config){
    posts.forEach((post, index) => {
        post.style = config[index]
    })
}



const catIds = [1,2,3,4,5,6,7,8]


 
// mergeStyles(trending, trendingConfig)

export default function Blog() {

    const {data, error, loading} = useQuery(GET_POSTS_BY_CATEGORIES_QUERY, {
        variables: {
            cat_ids: catIds
        }
    })


   

    if(error) return `error: ${error}`

    if(loading) return 'Loading...'


    
    return (
        <main className='blog'>
        <section className='container blog'>
            <div className='row'>
            <h1>Recent Posts</h1>
                {data.posts && 
                <PostMasonry posts={data.posts} columns={2} tagsOnTop={true}></PostMasonry>
                }
            </div>

        </section>

        <section className='bg-white'>   
            <section className='container'>
                <div className='row'>
                    <h1>Recent Posts</h1>
                    {/* <PostGrid posts={data.posts}></PostGrid> */}
                </div>

            </section>
        </section>
        </main>
    )
}