import React from 'react'
import {Text, Box, Flex, Divider} from '@chakra-ui/react'

export default function TitleHeader(props) {
    return (
        <Flex flexWrap='wrap' justifyContent='center' height='20vh' marginBottom='2%'>
            <Box width='65%'>
                <Text textAlign='center' fontSize='40px' fontWeight='900' paddingTop='5%' paddingBottom='5%'>
                    {props.title}
                </Text>
                <Divider borderColor='black' height='1px' bg='black'/>
            </Box>    
                
        </Flex>
    )
}
