import { Divider, Text, Flex, Image } from "@chakra-ui/react";
import UsefulLinksButton from "./useful-links-button";

import FadeIn from "react-fade-in";

export default function OneImageInfo(props) {
  const textSeparator = (text) => {
    if (text !== undefined && text.includes("#")) {
      return text.split("#");
    }
    return [text];
  };
  return (
    <FadeIn>
      <Text
        textAlign="left"
        paddingBottom="2%"
        fontWeight="500"
        fontSize="30px"
      >
        {props.title}
      </Text>
      <Flex width="100%" wrap="wrap" justifyContent="center">
        {textSeparator(props.text).map((textItem) => (
          <Text
            textAlign="left"
            paddingLeft="23px"
            paddingBottom="20px"
            fontWeight="light"
            w="100%"
          >
            {textItem}
          </Text>
        ))}
      </Flex>
      <Flex
        paddingTop="50px"
        width="100%"
        paddingBottom="50px"
        justifyContent="center"
      >
        {props.image ? (
          <Image objectFit="cover" src={props.image} height="500px"></Image>
        ) : (
          <Text></Text>
        )}
      </Flex>
      <UsefulLinksButton />

      <Divider
        marginTop="7%"
        marginBottom="7%"
        borderColor="gray"
        height="0.1px"
        bg="gray"
      />
    </FadeIn>
  );
}
