import { Box, Grid, Text } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import FadeIn from 'react-fade-in'
import Scroll from 'react-scroll'

type Item = {
    title: string,
    component: string
}

interface Props {
    width: string,
    color: string,
    item: string,
    test: Item[]
    headings: string
    gap: number

}

interface State {
    currentlyPicked: string
}


var ScrollDown = Scroll.Link
var scroll = Scroll.scroller

export default function ProcessBubbles(props: Props) {

    const [state, setstate] = useState<State>({
        currentlyPicked: ""
    })


    const ScrollTo = (element: string, e: React.MouseEvent<HTMLParagraphElement, MouseEvent>) => {
        scroll.scrollTo(element, {
            duration: 1500,
            smooth: true,
            offset: -280
        })
        const targ = e.target as HTMLElement
        console.log(element)

        setstate({
            ...state,
            currentlyPicked: targ.innerText
        })
    }

    const rep = `repeat(${props.test.length}, 1fr)`

    let startingDelay = 100
    return (
        <>

            <Grid width={props.width} templateColumns={rep} gap={props.gap ? props.gap : 20}>
                {props.test.map(item =>
                    <FadeIn delay={startingDelay += 80}>
                        <Box cursor='pointer'
                            transition='all 0.7s'
                            _hover={{
                                transform: 'scale(1.05)'
                            }}
                            textAlign='center'
                            w="15em"
                            h="15em"
                            bg={props.color}
                            borderRadius='400'
                            onClick={(e) => ScrollTo(`chakra-text ${item.title} css-11ttu8s`, e)} >
                            {item.component}
                            <Text color='white' paddingTop='10%' fontSize='1.2em'>{item.title}</Text>
                        </Box>
                    </FadeIn>
                )}



            </Grid>
        </>
    )
}
