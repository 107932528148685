import { Box, Divider, Flex, Text } from "@chakra-ui/react";
import { isMobile } from "react-device-detect";
import "../../Assets/scss/processes.scss";
import ProcessBar from "../../Components/processes/processes-bar";

export default function Processes() {
  return (
    <Flex
      flexWrap="wrap"
      height={isMobile ? "150vh" : "90vh"}
      justifyContent="center"
    >
      <Flex flexWrap="wrap" width="100%" justifyContent="center" height="0vh">
        <Box width="65%">
          <Text
            textAlign="center"
            fontSize="40px"
            fontWeight="900"
            paddingTop="5%"
            paddingBottom="5%"
          >
            Processes.
          </Text>
          <Divider borderColor="black" height="1px" bg="black" />
        </Box>
      </Flex>
      <ProcessBar />
    </Flex>
  );
}
