import { Box, Divider, Flex, Image, Text } from "@chakra-ui/react";
import React from "react";
import FadeIn from "react-fade-in";
import { Parallax } from "react-parallax";
import bridgetown from "../Assets/images/StudioBehindPool-ValleyView.png";
import home1 from "../Assets/images/smallhaus-home.jpg";
import sustain from "../Assets/images/Sustain-01-plant2.jpg";
import PictureDivider from "../Components/home/picture-divider.jsx";
export default function Home2() {
  return (
    <>
      <Image src={sustain} hidden="none"></Image>
      <FadeIn>
        <Flex
          flexWrap="wrap"
          backgroundSize="cover"
          backgroundPosition="center"
          backgroundRepeat="no-repeat"
          backgroundImage="linear-gradient(180deg, rgba(255,0,0,0) 70% ,  #dfdad3 100%), url('/Sustain-01-plant2.jpg')"
          justifyContent="center"
          height="100%"
          marginBottom={{ base: "30%", md: "30%", lg: "10%" }}
        >
          <Flex
            flexWrap="wrap"
            justifyContent="center"
            width="60%"
            textAlign="center"
            h="500px"
            marginBottom={{ base: "10%", md: "0", lg: "0" }}
          >
            <Text
              fontWeight="100"
              fontSize="60px"
              textAlign="center"
              paddingTop="100px"
            >
              planet A design & living
            </Text>
            <Text textAlign="center" fontSize="20px">
              Planet A Design and Living specialises in designing aesthetically
              delightful, purposefully functional, quality spaces for You.
            </Text>
            <Text textAlign="center" fontSize="20px">
              To Live. To Work. To Visit. And above all….. To Enjoy.{" "}
            </Text>
          </Flex>
          <PictureDivider />
        </Flex>

        <Flex wrap="wrap" flexFlow="column wrap" height="100%">
          <Parallax bgImage={home1} strength={300}>
            <Box marginBottom="100px" h="50vh"></Box>
          </Parallax>

          <Flex
            paddingLeft="20%"
            display="block"
            justifyContent="center"
            width="80%"
            textAlign="center"
            h="auto"
            paddingTop="90px"
            paddingBottom="110px"
          >
            <Text
              paddingBottom="2%"
              textAlign="center"
              fontSize="30px"
              fontWeight="700"
            >
              Our Vision
            </Text>
            <Text textAlign="center" fontSize="19px" fontWeight="500">
              To be the best design solutions team for dwellings and associated
              infrastructure, using an "ecosystem approach", to ensure outcomes
              are achieved which serve the requirements of our clients whilst
              minimising impacts on our planet.
            </Text>
          </Flex>

          <Parallax bgImage={bridgetown} strength={300} bgImageSizes="100px">
            <Box marginBottom="100px" h="50vh"></Box>
          </Parallax>

          <Flex
            paddingLeft="20%"
            display="block"
            justifyContent="center"
            width="80%"
            textAlign="center"
            h="auto"
            paddingTop="90px"
            paddingBottom="110px"
          >
            <Text
              paddingBottom="2%"
              textAlign="center"
              fontSize="30px"
              fontWeight="700"
            >
              Our Mission
            </Text>

            <Text fontSize="19px" fontWeight="500">
              To design the best dwellings and associated infrastructure for
              families and small business in a manner which meets client needs
              and expectations and improves environmental outcomes.<br></br>
              <br></br> We will achieve this through the use of passive solar
              design principles and recommending the use of sustainable and
              recyclable products.
            </Text>
          </Flex>
        </Flex>
      </FadeIn>
    </>
  );
}
