import { useMutation, useQuery } from '@apollo/client';
import React, { useCallback } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useParams } from 'react-router-dom';
import { CREATE_BLOG_POST, GET_POST } from '../queries/posts';

export default function EditPost(props) {

    const [addPost] = useMutation(CREATE_BLOG_POST)

    const {id} = useParams();
    const idAsInt = parseInt(id)

    const {data, error, loading} = useQuery(GET_POST, {
        variables: {
            id: idAsInt
        }
    })


    const getQuillDelta = useCallback((value, delta, source, editor) => {
        //ideally we will use this to call the mutation to save the post back to the db
        // console.log(value, delta, source, editor)
    }, [])

    if(loading) return 'Loading...'
    if(error) return `Error blah ${error.message}`

    return (
        <main className='post-editor'>
            <ReactQuill value={data.post.description} onChange={getQuillDelta}/>
        </main>
    )
}