import http from "./api-config";

interface EnquiryInformation {
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
  postcode: number;
  enquiry_reason: string;
  question_notes: string;
}

class EmailFormService {
  getAll() {
    return http.get("/emailform/");
  }

  create(data: EnquiryInformation) {
    return http.post("/emailform/", data).catch((error) => {
      console.log(error.response);
    });
  }
}

export default new EmailFormService();
