import {
  Box,
  Button,
  Divider,
  Flex,
  Image,
  Stack,
  Text,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
} from "@chakra-ui/react";
import React from "react";
import { isMobile } from "react-device-detect";
import FadeIn from "react-fade-in";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Link } from "react-router-dom";
import DesignSet from "../../Components/design/design-set";
import "../../Assets/scss/processes.scss";

//images

import GuestStudio from "../../Assets/images/Design-services/hausrange/GuestStudio-Ratio-16by9-Web.png";
import LongHaus from "../../Assets/images/Design-services/hausrange/Longhaus-Ratio-16by9-Web.png";
import MiniHausMk1 from "../../Assets/images/Design-services/hausrange/MiniHausMk1-Ratio-16by9-Web.png";
import MiniHausMk2 from "../../Assets/images/Design-services/hausrange/MiniHausMk2-Ratio-16by9-Web.png";
import MiniHausMk3 from "../../Assets/images/Design-services/hausrange/MiniHausMk3-Ratio-16by9-Web.png";
import SmallHaus from "../../Assets/images/Design-services/hausrange/SmallHaus-Ratio-16by9-Web.png";

import LongPod from "../../Assets/images/Design-services/podrange/LongPod-Ratio-16by9-Webv3.png";
import SettlersPod from "../../Assets/images/Design-services/podrange/SettlersPod-Webv4-low.png";
import SettlersPod2 from "../../Assets/images/Design-services/podrange/SettlersPod-Webv5-mod.png";
import BushPod from "../../Assets/images/Design-services/podrange/BushPod-HillSetting-Webv1-low.jpg";
import ShearersPod from "../../Assets/images/Design-services/podrange/ShearersPod-Webv3.png";

//pdfs
import gueststudioPdf from "../../Assets/PDFs/HausRange/Brochure-A4-GuestStudioPlus-S90-Webv4.pdf";
import longhausPdf from "../../Assets/PDFs/HausRange/Brochure-A4-LongHausPlus-S90-Webv3.pdf";
import minihausmk1Pdf from "../../Assets/PDFs/HausRange/Brochure-A4-MiniHausPlus-Mk1-S90-Webv4.pdf";
import minihausmk2Pdf from "../../Assets/PDFs/HausRange/Brochure-A4-MiniHausPlus-Mk2-S90-Webv3.pdf";
import minihausmk3Pdf from "../../Assets/PDFs/HausRange/Brochure-A4-MiniHausPlus-Mk3-S90-Webv3.pdf";
import smallhausPdf from "../../Assets/PDFs/HausRange/Brochure-A4-SmallHausPlus-S90-Webv5.pdf";

import bushpodpdf from "../../Assets/PDFs/PodRange/Brochure-A4-BushPod-T125-Webv2.pdf";
import longpodpdf from "../../Assets/PDFs/PodRange/Brochure-A4-LongPod-T125-Webv3.pdf";
import shearerpodpdf from "../../Assets/PDFs/PodRange/Brochure-A4-ShearersPod-T125-Webv3.pdf";
import settlerspodpdf from "../../Assets/PDFs/PodRange/Brochure-TheSettlersPod-Webv4.pdf";

import TitleHeader from "../../Components/common/titleHeader";

export default function DesignServices() {
  const color = "gray.700";
  const fontColor = "black";

  const hausSeries = [
    {
      itemName: "The Guest Studio",
      image: GuestStudio,
      pdf: gueststudioPdf,
    },
    {
      itemName: "The Long Haus",
      image: LongHaus,
      pdf: longhausPdf,
    },
    {
      itemName: "The Mini Haus Mk1",
      image: MiniHausMk1,
      pdf: minihausmk1Pdf,
    },
    {
      itemName: "The Mini Haus Mk2",
      image: MiniHausMk2,
      pdf: minihausmk2Pdf,
    },
    {
      itemName: "The Mini Haus Mk3",
      image: MiniHausMk3,
      pdf: minihausmk3Pdf,
    },
    {
      itemName: "The Small Haus",
      image: SmallHaus,
      pdf: smallhausPdf,
    },
  ];

  const podSeries = [
    {
      itemName: "The Bush Pod",
      image: BushPod,
      pdf: bushpodpdf,
    },
    {
      itemName: "The Long Pod",
      image: LongPod,
      pdf: longpodpdf,
    },
    {
      itemName: "The Settlers Pod",
      image: SettlersPod,
      pdf: settlerspodpdf,
    },
    {
      itemName: "The Settlers Pod",
      image: SettlersPod2,
      pdf: settlerspodpdf,
    },
    {
      itemName: "The Shearers Pod",
      image: ShearersPod,
      pdf: shearerpodpdf,
    },
  ];

  const compactSeries = [
    {
      itemName: "The Narrow House",
      pdf: "",
    },
    {
      itemName: "The Tenner",
      pdf: "",
    },
    {
      itemName: "The Wide House",
      pdf: "",
    },
    {
      itemName: "The Graphite House",
      pdf: "",
    },
    {
      itemName: "The White House",
      pdf: "",
    },
  ];

  return (
    <FadeIn transitionDuration="600">
      <TitleHeader title="Design Services." />
      <Tabs defaultIndex={0} variant="unstyled" colorScheme="teal">
        <TabList paddingLeft="17%">
          <Tab _selected={{ color: "white", bg: "#393d40" }}>
            Custom Designs
          </Tab>
          <Tab marginRight="30px" _selected={{ color: "white", bg: "#393d40" }}>
            Pre-Made Designs
          </Tab>
        </TabList>

        <TabPanels>
          <TabPanel display="flex" justifyContent="center">
            <Flex
              minH="60vh"
              direction="column"
              maxW="50%"
              textAlign="center"
              pt="150px"
            >
              <Text pb="15px" fontWeight="700" fontSize="1.7em">
                Custom Designs
              </Text>
              <Text fontWeight="700" pb="10px">
                Know what you want, but finding it elusive?
              </Text>
              <Text>
                A Custom Design is your answer. We translate your ideas (and
                mud-maps) into your beautiful dream home.
              </Text>

              <Text>
                To ensure that we meet or exceed your expectations we have
                formulated a unique approach to dwelling design that ensure a
                great outcome for you.
              </Text>
              <Text pt="20px" pb="20px" fontStyle="italic">
                We have called it an “Eco-system approach to Design”.
              </Text>
              <Link href="tel:0404834844">
                <Text>Call Ian on 0404 834 844</Text>
              </Link>
              <Text>
                Click "Coffee or Query" in the top right to contact Ian directly
              </Text>
              <Link to="/processes">
                <Text>Further information in Processes</Text>
              </Link>
            </Flex>
          </TabPanel>
          <TabPanel>
            <Stack alignItems="center">
              <DesignSet
                title="The Haus Series."
                items={hausSeries}
                info="Our Haus series is all about staying put and adding a Studio, Granny Flat, Teenager Retreat, Man or Woman Cave, or Air BnB Accommodation. If they aren’t quite meeting your needs we can modify them or provide a Custom Design for you."
              />
              <DesignSet
                title="The Pod Series."
                items={podSeries}
                info="Our Pod Series is all about providing for “the great escape” for them city folk. So whether you have half an acre or several thousand, we have some great pre-designed plans to help you get the show on the road. If they aren’t quite meeting your needs we can modify them or provide a Custom Design for you."
              />
              <DesignSet
                title="The Compact Series."
                items={compactSeries}
                info="Our Compact Series is all about providing designs which are suited to your typical city or country town suburban block, whether your needs are for a single or double storey home. . If they aren’t quite meeting your needs we can modify them or provide a Custom Design for you."
              />
            </Stack>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </FadeIn>
  );
}
