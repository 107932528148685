import gql from 'graphql-tag'

export const GET_POSTS_BY_CATEGORIES_QUERY = gql`
    query GetBlogPostsByCategories($cat_ids: [Int]){
        posts: getPostsByCategories(cat_ids: $cat_ids){
            id
            title
            description
            updated_at
            author
            image
            categories
        }
    }
`

export const GET_FEATURED_POSTS = gql `
    query getFeaturedBlogPosts($type: String) {
        posts: getFeaturedPosts(type: $type){
            id
            title
            description
            updated_at
            author
            image
            categories
        }
    }
`


export const GET_POST = gql `
    query getPost($id: Int) {
        post: getPost(id: $id){
            id
            author_id
            title
            text
            keyword1
            keyword2
            bg_src
            bg_type
            categories
            author
            description
            image
            likes
            # comments {
            #     id
            #     post_id
            #     author
            #     comment
            #     author_image
            # }
            updated_at
            active
            created_at
        }
    }
`


export const CREATE_BLOG_POST = gql`
    mutation createPost($data: PostInput) {
        createPost(data: $data) {
            title,
            categories,
            description,
            image,
            keyword1,
            keyword2,
            bg_src,
            bg_type
        }
    }
`
