import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Divider,
  Flex,
  Image,
  Text,
} from "@chakra-ui/react";
import React from "react";
import FadeIn from "react-fade-in";
import sustain from "../Assets/images/Sustain-01-plant2.jpg";
import Better from "../Assets/images/Why/BetterChoice-03.jpg";
import Design from "../Assets/images/Why/BetterDesign-02.jpg";
import Compact from "../Assets/images/Why/CompactLiving-01.jpg";
import Modular from "../Assets/images/Why/ModularDesign-01.jpg";
import Offsite from "../Assets/images/Why/Offsite-06.jpg";

import TitleHeader from "../Components/common/titleHeader";

export default function Why() {
  var whyItems = [
    {
      title: "Better By Choice",
      image: Better,
      items: [
        {
          heading: "One Stop Shop",
          text: "We have a full suite of services available for you which are designed to remove the stress and hassle which often accompanies building a new home.",
          link: "Management",
        },
        {
          heading: "Honest & Open",
          text: "We keep things simple. Our experiences over the past 20 years has taught us that informing and educating our clients is conducive to the best relationships. Everyone benefits when knowledge replaces assumptions.         ",
          link: "Being Informed",
        },
        {
          heading: "Your Design, One Design",
          text: "You can control the plan. This means that quotes are based on your plan and not each builders’ plan. This is the only way to be able to “compare apples for apples”.",
          link: "Management",
        },
        {
          heading: "End Cost Savings",
          text: "By taking out the “middlemen” (Design/Sales Consultants and Brokers), the builder has lower costs which are passed onto you. ",
        },
        {
          heading: "Total Flexibility",
          text: "Engage planet A for one, two of all our service options. You can continue with your life while we beaver away in the background.",
          link: "Management",
        },
      ],
    },
    {
      title: "Better by Design",
      image: Design,
      items: [
        {
          heading: "Life-span adaptability",
          text: "Our “ecosystem” approach to Design & Living ensures that we fully understand your requirements, both for today and for “tomorrow”. The result is a space, a home which grows with you and can be adapted down the track, if necessary.",
        },
        {
          heading: "Cost saving",
          text: "We understand how the builders’ estimating and construction processes work. This knowledge allows us to design to minimise waste, which results in lower quotes for you.",
        },
        {
          heading: "Lifestyle Functional",
          text: "We take the time to understand you and your family. In turn this ensures the functional aspects of our design matches perfectly your lifestyle.",
        },
        {
          heading: "Pragmatic, Solutions Focussed",
          text: "Try it on. Sloping sites. Tight sites. Difficult access. Specific living circumstances. We just love working through an issue until the correct design outcome or solution is found.",
        },
      ],
    },
    {
      title: "Better by the Planet",
      image: sustain,
      items: [
        {
          heading: "Renewable",
          text: "Eco-friendly materials selection is key to doing our bit to reduce our collective environmental impact. To this end we recommend the utilisation of products which have a low energy requirement to make. The prime example is the use of plantation timber for the majority of the structure.",
        },
        {
          heading: "Carbon Footprint",
          text: "All products recommended for use in the manufacture of your home are sourced as locally as possible, ensuring a low carbon footprint. We are currently working on new options to further reduce the carbon footprint of your home. Ask us how.",
        },
        {
          heading: "Recycling & Waste Management",
          text: "Due to the materials we select, the waste which is produced during the manufacturing of your home is minimal and recycled responsible. When your home comes to the end of its’ useful life (at least 50 years down the track) it can be dismantled and its’ constituent components repurposed and recycled.               ",
        },
        {
          heading: "Passive Solar",
          text: "All our designs are focussed on passive solar principles – orienting your home relative to north to reduce the energy required to regulate the warming and cooling of your home. Materials use and colour can further enhance energy efficiency.                ",
        },
        {
          heading: "Life-cycle Running Costs",
          text: "Factory built and well maintained homes have an increased life expectancy. Couple this with the fact we design highly energy efficient homes, the result is what planet A design & living philosophically strives to do – lower the environmental impact of us living a wonderful life, so that those who come after us can enjoy a life and lifestyle which we take for granted.",
        },
      ],
    },
    {
      title: "Modular Design",
      image: Modular,
      items: [
        {
          heading: "Fast Delivery",
          text: "Builders using offsite construction deliver fast. Once all your permits are in place, the manufacturing of your home can be measured in days, perhaps weeks, but definitely not months. Once delivered to site, the same applies. It’ll be days to complete all the work which cannot occur off-site. In all likelihood it will take longer to get the relevant state & local government permits than to manufacture and deliver you home!",
        },
        {
          heading: "Affordability",
          text: "Modular is now mainstream. The cost controlled environment which modular and off-site manufacturing offer means the cost of construction now matches and often beats traditional forms of construction.                ",
        },
        {
          heading: "Timeline Control",
          text: "Off-site manufacturing in a controlled environment means that progress is made each and every day. That’s why time is measured in days and weeks, not months.                ",
        },
        {
          heading: "Move-in Ready",
          text: "Builders are happy to complete your home, down to the washing line if necessary. This means that once your home is connected to the site services, your home is ready to be lived in.                ",
        },
        {
          heading: "Minimal Site Impacts",
          text: "We recommend using the Surefoot foundation footing system. No concrete and generally no digging. Gardens and trees love it. And so will your wallet.",
          link: "being informed",
        },
      ],
    },
    {
      title: "Off-site manufacturing",
      image: Offsite,
      items: [
        {
          heading: "Accuracy",
          text: "Cutting edge computer software drives automated measuring, cutting, manufacturing and construction. This provides millimetre accuracy. There is no bad workmanship, something regular builders can only dream of.",
        },
        {
          heading: "Control",
          text: "All manufacture and construction occurs under one roof, resulting in improved quality control over the various employed trades people.",
        },
        {
          heading: "Efficiency of Construction",
          text: "More supervision delivers less downtime which means faster production. Loyal employees, not unknown sub-contractors, results in improved accountability and higher quality faster turn-around times.                ",
        },
        {
          heading: "Materials Storage",
          text: "Facility storage results no site loss/theft, materials damage or limited space issues, all of which occur onsite using traditional construction methods.",
        },
        {
          heading: "Weather",
          text: "A climate controlled work environment means no time loss due to heat, wet or cold.",
        },
      ],
    },

    {
      title: "Compact living",
      image: Compact,
      items: [
        {
          heading: "Build what you need",
          text: "What to do you want in your home? What size suits you? Based on your brief we deliver a design which takes care of your each and every need.",
        },
        {
          heading: "Don’t do without",
          text: "Do you love all the mod-cons? Do you want architecturally stunning external facades?  Again, we will deliver a design which takes care of all your wants.",
        },

        {
          heading: "No to McMansions",
          text: "We design spaces and homes that meet your needs. No one wishes they had more cleaning to do.",
        },
      ],
    },
  ];

  const Dropdowns = (item) => {
    return item.items.map((dropdowns) => (
      <Accordion allowToggle={true} allowMultiple={true}>
        <AccordionItem padding="10px">
          <h2>
            <AccordionButton>
              <Box textAlign="left">{dropdowns.heading}</Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>{dropdowns.text}</AccordionPanel>
        </AccordionItem>
      </Accordion>
    ));
  };

  return (
    <FadeIn>
      <Flex
        flexWrap="wrap"
        justifyContent="center"
        alignContent="center"
        flexDir="column"
      >
        <TitleHeader title="Why planet A design" />
        {whyItems.map((item) => (
          <Flex maxW="65%" flexDirection={{ base: "column", md: "row" }}>
            <Flex
              paddingRight="10%"
              flexDirection="column"
              className="test"
              width="90%"
            >
              <FadeIn delay="200">
                <Text
                  fontSize="20px"
                  fontWeight="700"
                  paddingTop="9%"
                  paddingBottom="2%"
                >
                  {item.title}
                </Text>
                {Dropdowns(item)}
              </FadeIn>
            </Flex>
            <Flex
              width="100%"
              position="relative"
              paddingTop={{ base: "10%", md: "5%" }}
            >
              <FadeIn delay="400">
                <Image
                  objectFit="cover"
                  width="1500px"
                  height="300px"
                  alignSelf="center"
                  src={item.image}
                ></Image>
              </FadeIn>
            </Flex>
          </Flex>
        ))}
      </Flex>

      <Box height="200px"></Box>
    </FadeIn>
  );
}
