import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Avatar } from "antd";
import {
  Button,
  Menu,
  MenuButton,
  Text,
  Flex,
  Image,
  Box,
} from "@chakra-ui/react";
import { ContactModelButton } from "../Components/common/index";
import { isMobile, BrowserView, MobileView } from "react-device-detect";
import logo from "../Assets/images/Navbar/Logo-EcoCompactHomes-WhiteBackground-Plain-V1.0.jpg";
const navLinks = [
  // {
  //   title: 'Home Designs',
  //   path: '/home-designs'
  // },
  {
    title: "Home",
    path: "/",
  },
  {
    title: "Why planet A design",
    path: "/why",
  },
  {
    title: "Design Services",
    path: "/design-services",
  },
  {
    title: "Management Services",
    path: "/management-services",
  },
  {
    title: "Processes",
    path: "/processes",
  },
  {
    title: "Your benefits",
    path: "/benefits",
  },

  // {
  //   title: 'Inspiration',
  //   path: '/inspiration'
  // },
  {
    title: "Being Informed",
    path: "/informed",
  },
  {
    title: "FAQ",
    path: "/faq",
  },

  // {
  //   title: 'Blog',
  //   path: '/blog'
  // },
];

export default function Navigation() {
  const [menuActive, setMenuActive] = useState(false);

  return (
    <>
      <Link to="/">
        <Box
          className="logo-image"
          top="0"
          position="fixed"
          zIndex="1001"
          width="300px"
        >
          <Image src={logo}></Image>
        </Box>
      </Link>
      <nav
        className={`site-navigation ${menuActive && "active"}`}
        role="navigation"
      >
        <div className="menu-content-container">
          <ul>
            {navLinks.map((link, index) => (
              <li onClick={(ev) => setMenuActive(!menuActive)} key={index}>
                <Link to={link.path}>{link.title}</Link>
              </li>
            ))}
          </ul>
        </div>

        <i
          className="icon ionicons ion-ios-menu"
          onClick={(ev) => setMenuActive(!menuActive)}
        />
      </nav>
      <Box
        right="0"
        paddingTop="30px"
        paddingRight="5em"
        className="logo-image"
        top="0"
        position="fixed"
        zIndex="1001"
      >
        <BrowserView>
          <ContactModelButton />
        </BrowserView>
      </Box>
    </>
  );
}
