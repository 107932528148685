import React from "react";
import FadeIn from "react-fade-in";
import ScrollUp from "../Components/common/scrollUp";
import {
  BookOutlined,
  EditOutlined,
  HighlightOutlined,
  TeamOutlined,
} from "@ant-design/icons";
import { Box, Divider, Flex, Text, Stack, Grid, Image } from "@chakra-ui/react";
import { BrowserView, isMobile } from "react-device-detect";
import ProcessBubbles from "../Components/processes/process-bubbles";

import OneImageInfo from "../Components/common/1-image-info";
import ThreeImageInfo from "../Components/common/3-image-info";
import RightImageInfo from "../Components/common/right-image-info";
import LeftImageInfo from "../Components/common/left-image-info";

//images
import Quote from "../Assets/images/Processes/management/07-TheQuote-03.png";
import Permit from "../Assets/images/informed/building-permit.png";
import State from "../Assets/images/informed/StateAdministrativeTribunal-02.jpg";
import Energy from "../Assets/images/informed/EnergyEfficiency-02.webp";
import Bushfire from "../Assets/images/informed/BushfireRating-01.jpg";
import Sustain from "../Assets/images/informed/Sustainability-03.png";

const color = "gray.700";

export default function BeingInformed() {
  let test = [
    {
      title: "Your State",
      component: (
        <HighlightOutlined
          style={{ fontSize: "3vw", paddingTop: "20%", color: "white" }}
        />
      ),
    },

    {
      title: "Development Approval",
      component: (
        <TeamOutlined
          style={{ fontSize: "3vw", paddingTop: "20%", color: "white" }}
        />
      ),
    },
    {
      title: "Council Permits",
      component: (
        <BookOutlined
          style={{ fontSize: "3vw", paddingTop: "20%", color: "white" }}
        />
      ),
    },

    {
      title: "Energy Efficiency",
      component: (
        <HighlightOutlined
          style={{ fontSize: "3vw", paddingTop: "20%", color: "white" }}
        />
      ),
    },
    {
      title: "Bushfire Rating",
      component: (
        <HighlightOutlined
          style={{ fontSize: "3vw", paddingTop: "20%", color: "white" }}
        />
      ),
    },
    {
      title: "Sustainability",
      component: (
        <HighlightOutlined
          style={{ fontSize: "3vw", paddingTop: "20%", color: "white" }}
        />
      ),
    },

    {
      title: "Administrative Tribunal",
      component: (
        <EditOutlined
          style={{ fontSize: "3vw", paddingTop: "20%", color: "white" }}
        />
      ),
    },
  ];

  return (
    <>
      <FadeIn transitionDuration="600">
        <ScrollUp />

        <Flex textAlign="center" flexWrap="wrap" justifyContent="center">
          <Flex flexWrap="wrap" width="100%" justifyContent="center">
            <Box width="65%">
              <Text
                textAlign="center"
                fontSize="40px"
                fontWeight="900"
                paddingTop="5%"
                paddingBottom="5%"
              >
                Being Informed.
              </Text>
              <Divider borderColor="black" height="1px" bg="black" />
            </Box>
          </Flex>

          <Flex justifyContent="center" mt="100px" mb="100px">
            <BrowserView>
              <ProcessBubbles test={test} color={color} width="100%" gap={10} />
            </BrowserView>
          </Flex>

          <Stack
            wrap="wrap"
            textAlign="center"
            width="65%"
            justifyContent="center"
          >
            <ThreeImageInfo
              title="Your State"
              text="In terms of construction, the State’s involvement should remain completely in the background unless your happen to be building in a specially dedicated area where State responsibility overrides Council responsibility, or you need to utilise the Administrative Tribunal to solve a dispute relating to a Council planning decision. The State is also responsible of governing private land and titles. If you are sub-dividing or amalgamating land, you will be completing it via the State “land titles office”. Below are the web addresses for each State Planning Department and land titles office:"
            />
            <ThreeImageInfo
              title="Development Approval"
              text="A development approval (DA) is often the first part of the approvals process relating to dwelling or other infrastructure to be constructed on your site. Your plans are provided to council along with any supporting documentation - for assessment, determination and the issuing of the DA by the Council Planning Department. Generally your application is handled by either your Designer, your Architect or your Builder and during the process they liaise with the assigned Planner, should issues of concern arise. Although each State or Territory has their own state wide policies, your Concil likely also has their own Local Planning Policies (LPPs). Whilst LPPs may apply to the entire local government area, there may be LPPs which only cover specific areas within the local government area.# 
The Planner employed by your Council assesses your application against all policies which govern the area where your site is located. Unfortunately it is often the case that the policies are written in a way which may result in different interpretations for the intent of the policy. Add to this the fact that there may be an amount of discretion available to the Council planners when assessing a DA that has not met all the criteria as set out in the policy. This discretion may be at the Planner level, the Planning Department Head level, or at the Councillor level. In the last case, the Planning Department will either recommend for approval or against approval, however the decision is made by the Councillors at a Council Meeting.#
Your Designer should familiarise themselves with the relevant policies for your site location, and take them into account when bringing your design brief to life. They will generally foresee potential issues with the design they develop for you and advise you accordingly. It is up to you whether you proceed and modify the design to be “compliant” or whether you wish to present your plans for determination by the Council planners exactly as you would like it built and then work with the Planning Department to resolve any issues which they deem “too far” outside policy boundaries.# 
One of the most frustrating aspects of the planning process relates to the state wide policies – my interpretation is that due to politics at the local government level, the planning department of one Council interprets an aspect of the state policy framework differently from another Council planning department.#
Depending on how “compliant” your plans are and what internal political agendas exist & operate within your Council will help determine whether there is merit in employing a local Town Planner as your representative, to liaise with the assigned Council Town Planner. Your Designer or Architect may have one on staff, or will be able to recommend one should it be necessary.
As an aside, most builders are not interested in planning issues. They just want to build, so will ensure that they present plans that are compliant (if they are project builders) or are likely to strongly encourage you to modify your home so it is compliant. They generally get you emotionally involved, get the house quoted and then say that delays caused due to planning snags will result in your quoted price going up, due to the delay it is causing them. This is another good reason to employ an external Designer – it is your plan, your house. So get a DA via your Designer, and then go visit the builders."
            />
            <RightImageInfo
              image={Permit}
              title="Building Certification & Permits - Local Government"
              text="A Building Approval differs from a Development Approval in many ways, however there are a couple which clearly delineate the difference:#
    1. You always need a Building Permit, issued by Council prior to construction of your new home or most other structures. However a Development Approval may not be required.
    # 2. To get a Building Permit, your plans need to be compliant with the National Construction Code (NCC), the Australia wide manual produced by the Australian Building Codes Board (ABCB), on behalf of the Australian Government and each State and Territory government. More information is available at www.abcb.gov.au and www.ncc.abcb.gov.au, including being able to download the two volumes of the Codes themselves. A DA does not consider compliance with the NCC.
# To obtain a building permit/approval the Council Building Surveyor assesses your plans and associated information against the NCC to ensure that when your home is constructed, it is done so in accordance with the codes. The type of additional information which is submitted to Council includes, but may not be limited to:
    # 1. Your plans, elevations and internals, including detail drawings as necessary
    # 2. Detailed site plan
    # 3. Signed Engineers plans, including detail drawing as necessary
    # 4. Energy Efficiency Report
    # 5. Bushfire (BAL) Report

# There may be the option of submitting certified plans to Council. This occurs where you employ a private Building Surveyor to complete all the required paperwork to confirm that all your information is included as required, and is compliant with the NCC. They provide you with a certified set of documents, which can then be submitted to Council. The main reason for using this option is timeframes are significantly reduced, which means that your builder can begin construction sooner."
            />
            <LeftImageInfo
              title="Energy Efficiency."
              text="In terms of homes, improving energy efficiency involves using construction materials and techniques which reduce the amount of external energy required to “run the home”. The Australian Government has implemented a star rating system (NatHERS) which is used to assess a home’s energy efficiency. The rating system considers the materials used and the orientation of your home, relative to north and which rooms are located where, within your home amongst other things.# 
The pie chart shows the breakdown of average household energy use for the whole of Australia, based on Baseline Energy Estimates – circa 2014. 
source - https://www.sa.gov.au/topics/energy-and-environment/using-saving-energy/home-energy-use
The aspect of greatest significance is that almost two thirds of the total external energy requirements are consumed in just 2 areas – heating and cooling the home and heating of water.
The design and orientation of your home is of utmost importance in terms of “lifetime” energy consumption. When you consider that your home will consume energy for 30, 40 or even 50 years, the savings to be made are measured in tens of thousands of dollars. When you realise this, it makes sense to ensure that your home is as energy efficient as possible by using materials and techniques which reduce the requirement for external energy consumption.
The NatHERS star system rates your home from 1 to 10. Currently most States require homes to rate at 6 stars or above, however many are reviewing this with the aim of increasing the requirement to 7 stars. We recommend that you instruct your Designer to ensure a minimum 7 star rating is achieved, with a preferred goal of 8 stars. The savings you make by reducing the energy consumption of your new home will more than pay for the improvements you include. And using solar orientation to best effect is entirely free.
Although each State produces its’ own information, you can look to any of the websites below to find additional information:
"
              image={Energy}
            />
            <RightImageInfo
              image={Bushfire}
              title="Bushfire Rating"
              text="A Bushfire Rating (BAL) is required in many parts of Australia and there is an Australian Standard associated with it; AS 3959:2018. The BAL rating determines the construction and material requirements your home must comply with, so it is appropriately protected from bushfire. 
# The rating is ongoing, which means that you have a responsibility to maintain the land around you (assuming it is your land) so that the grass, shrubs and trees do not grow to the point that the rating has moved up the scale. Therefore if you clear the area to bring you BAL rating down, you must maintain that level of clearing as a minimum. Conversely, if you would like to plant a garden around your new home or tourism complex, you need to ensure that as the plants grow, they do not result in the BAL rating going up to the next level. In both cases, you have the option to request that your final design complies to a higher BAL level than the BAL Report states."
            />
            <OneImageInfo
              image={Sustain}
              title="Sustainability"
              text="Sustainable or Green Building is quite an interesting subject because one can consider it from many viewpoints. The associated image provides a great reference point to consider what sustainability is because you can add different weights to the importance of each of the three main aspects noted – Social, Environmental and Economic. 
# Sustainability is also about timeframes or lifecycle. As a community, time has come to the fore as we are impacted by climate change and all the repercussions which flow from it. #
When designing a home or dwelling we listen to your story, what your needs and wants are, and how these fit with your concept of sustainability as an individual, family or business. We call this Pragmatic Sustainability. # We are pragmatic enough to realise that it is our role is to suggest and educate, rather than play big brother. However, there are products on the market which we feel are one step too far from being considered sustainable given they are long lived and most or all of the product is not readily recyclable, and we strongly urge against their use. #
Your Council. We go nowhere without consent from our local Council. The staff are usually helpful and whilst their interpretation of policy “rules” and your desires may not always align perfectly, they play a vital role in helping us all as a community. Below is our set of “golden rules”, which have served us well over the years: #
    1. Call and chat with the Planning Department as early as practical to discuss your project. The aim is to get an understanding of how well your project aligns with any Council policies. They may give you useful information that you were not aware of. We love working with clients who are motivated to learn and understand.
    # 2. Never assume that a scenario which was given a permit “yesterday” is a guarantee that exactly the same scenario will be given a permit “today”. And it might be even less likely to receive a permit “tomorrow”. 
    # 3. Do not make a phone call unless you have a pen and paper ready. Write down:
        # a. The name of each person you speak to, from receptionist onward. Also note the department position they hold.
        # b. Take notes as you go, particularly if you ask questions.
    # 4. Ask questions carefully. How you ask the question can impact on whether or not you get an answer which provides you with reliable or useful answer. 
    # 5. Consider whether it is prudent to send a follow-up email to the person giving you answers, to ensure that your understanding stacks up with reality.
# Click the link to your local Council. Type in “Local Planning Policies” and see what comes up….."
            />
            ``
            <RightImageInfo
              title="State Administrative Tribunal"
              text="Each State has an Administrative Tribunal which allows all citizens to take their complaints to a higher and independent level. From a building perspective, you are unlikely to utilise this service as it is generally considered an avenue of last resort. However, should your DA be headed in a direction you dis-agree with, or be refused, your state’s tribunal becomes the avenue for dispute resolution. If you have not already employed a Town Planner, employing one at this point is worth its weight in gold. #
Ending up at the tribunal is not common when building residential dwellings unless you are trying to achieve an outcome which is unusual or is pushing the boundaries of the policies which apply to the area where your site is located. For your convenience each State Administrative Tribunal website can be found at:"
              image={State}
            />
          </Stack>
        </Flex>
      </FadeIn>
    </>
  );
}
