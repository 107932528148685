import React from 'react';
import HouseDesignFeature from '../Components/housedesigns/HouseDesignFeature'

const backyardItems = [
    'The Micro Pod',
    'The Short Pod',
    'The Long Pod',
    'The Double Pod',
    'The Big Pod',
    'The Social Pod',

]

export default function Tourism() {
    return (
         <HouseDesignFeature  title='Eco Tourism & Agri-Tourism' description='backyard description' headings={backyardItems} image=''/>  
        )
}