import {
    Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Flex,
    Image, Text
} from '@chakra-ui/react'
import { Divider } from 'antd'
import React from 'react'
import FadeIn from 'react-fade-in'


//images
import Design from '../Assets/images/faq/YourDesign-02.jpg'
import House from '../Assets/images/faq/YourInclusions-04.jpg'
import Site from '../Assets/images/faq/YourSite-04.jpg'
import Inclusions from '../Assets/images/faq/YourInclusions-01.jpg'
import Paperwork from '../Assets/images/faq/Paperwork&Permits-04.jpg'
import Planet from '../Assets/images/faq/OurPlanet-01.jpg'
import Time from '../Assets/images/faq/pexels-tara-winstead-7123604.jpg'


export default function Why() {


    var faqGroups = [
        {
            title: 'Your Design',
            image: Design,
            items: [
                {
                    heading: "Why use a Designer instead of going to the Builder direct?",
                    text: "It all comes down to your dream home, the perfect building design for your business venture. If you go direct to a Builder you run into a simple problem – they are in control, not you. Copyright law exists to protect the owners of work – including building designs - from having their work copied without permission. A builder will not let you take the plans they produce, based on your ideas, to another builder for quotes and construction. By utilising planet A design & Living you solve this problem and therefore you stay in control. planet A design & Living can issue you with a license agreement which gives you rights for the use of the designs and other documentation produced. You benefit by issuing one design and associated details to all your prospective builders to quote from."
                },
                {
                    heading: "Should we get the plans DA approved?",
                    text: "We recommend that you engage planet A design & living to apply for a DA on your behalf. This step ensures that the builder has no delays and can go straight to Building Permit application. Whilst the Building Permit process is straight forward, the DA approval process may not be, due to council policies or neighbour comments. Whilst Builders are good at construction, they are not good at negotiating with Council! During the design process we will have informed you about aspects of the plans or the site which may need negotiation as part of the DA approvals process, so engaging us ensures the process goes as smoothly and quickly as possible."
                },
                {
                    heading: "Why use planet A design & living?",
                    text: "Ian Meyer, founder of planet A design & living, has just over 30 years’ experience in all aspect of design, engineering and construction of new dwellings. Many designers are solely that – designers - and it is not until quote stage that you find out how important it is to understand engineering and construction when designing a house. Unfortunately the designer is not paying for your house – you are."
                },
                
            ]
        },
        {
            title: 'Your House',
            image: House,
            items: [
                {
                    heading: 'Why consider building a modular home?',
                    text: 'Building a modular home comes with many benefits. Build time is incredibly short and they are generally more affordable than traditionally constructed homes. They have less environmental impact on both your site and the environment. Modular also provides fantastic design flexibility and out builder partners are happy to include exactly what you want, from the bare essentials through to an “all included” turnkey package.'
                },
                {
                    heading: "Are there design restrictions for Custom Modular Homes?",
                    text: "Building the Modular way has no limits. It is at least as flexible as other construction methods, so you can still have exactly what you want and need."
                },
                {
                    heading: "Can I change a Pre-designed floor plan?",
                    text: "Yes you can. We will advise you if it makes more sense to go down the Custom designed path."
                },
                {
                    heading: "What size are the modules?",
                    text: "There is technically no limit to module size, however transportation tends to be the limiting factor. As the width of a module increases so does the cost of transportation due to the requirement of pilot vehicles and potentially a police escort. Module widths are generally best kept below 3500mm overall. Lengths can also come into play however are rarely an issue. Most good manufacturers of modular homes can adjust their construction techniques to ensure you are able to achieve room sizes which match your requirements, to the point of manufacturing individual wall sections which are assembled onsite. The only other consideration is height. Getting components out of the factory along with low bridges, powerlines and trees need to be considered, however having single panel wall heights up to 3500mm can be considered. Should you need higher, then the panels are joined onsite – so no problem."
                },
                {
                    heading: "Can I attach modules to my existing house as an extension?",
                    text: "The short answer is yes you can. However not all Builders are set up to complete this work. We can also design a “skin” to go over your existing home, which is an innovative way of achieving the look you want without undertaking demolition or other major construction works."
                },
                {
                    heading: "Timber frame or steel frame or Insulated Panels?",
                    text: "We recommend that a timber framed structure is built, using steel only where absolutely necessary for engineering requirements. The proponent for each system can argue the benefits of their system over the others however a well-engineered modular timber frame gives you more, whilst the environmental cost is far less. One can’t argue to go with one over the other due to cost, as the difference are not significant enough. Your justification really should be all about the environment for this one. (Just for the record – the tallest timber building in the world is in Norway and is 85.4 metres high. The majority of the materials used in its construction were sourced within 4 kilometres of the site)."
                },
                {
                    heading: "What heating and cooling options should I be considering?",
                    text: "This is one of the most important questions to be considered because it is a question that relates to the life of your home, which is going to be measured in decades – probably 4 or 5 of them. Currently the minimum requirement is an energy rating of 6 Stars. If this is all that is achieved then you are going to need “external” heating and cooling. As the star rating increases the requirement for this heating and cooling drops. And correspondingly so does the cost of running your home each and every year. If you save just $3,000 a year in energy bills over a 30 year time span, you are $90K better off – and that is in today’s dollars. Ask us more about this when we meet."
                },
            ]
        },
        {
            title: 'Your Site',
            image: Site,
            items: [
                {
                    heading: "Can modular homes by installed on sloping sites?",
                    text: "Modular homes can be sited just about anywhere. It comes down to the footing system and engineering it appropriately for the slope of your site. The footing system we recommend is suited to most sites and creates the flat surface needed to install the modules or panels onto. This site work is completed whilst your home is being manufactured, further reducing the time needed to complete the contracted works. "
                },
                {
                    heading: 'Who completes the site works?',
                    text: "Building modular generally reduces the required site-works. You still need to connect to services or have septic tanks installed, however we recommend a proprietary footing system which results in very minimal impact to the ground where your new home will be located.  Depending on your location and the builder you choose, you may be able to complete some or all of the site works. Ask us about this when we meet."
                },
                {
                    heading: "Can modular homes by installed on narrow suburban sites?",
                    text: "Yes. Crane operators are akin to ballerinas. Their grace and dexterity in lifting, moving and positioning with millimetre perfection is awesome. Perhaps the biggest difference between a crane operator and a ballerina is the clothes they wear."
                },
                {
                    heading: "How much are transport and crane costs",
                    text: "Transporting your home to site and using a crane to position all the components is largely dependent on the distance of travel and the number of components to be positioned. An estimate is calculated early on at the preliminary price stage."
                },
            ]
        },
        {
            title: 'Your Inclusions',
            image: Inclusions,
            items: [
                {
                    heading: 'Do I get a concrete or structural timber floor?',
                    text: 'This is entirely up to you, as both options are available. From an economic and environmental perspective deciding to include a structural timber floor is the better option.'
                },
                {
                    heading: 'I know what I want included. How is this confirmed?',
                    text: 'Our “ecosystem approach” to design ensures that we have a comprehensive understanding of both what you want in the design of your home and what you want included with your home. As the house plan develops, the list of inclusions is documented with the result being an addenda and specifications document.'
                },
                {
                    heading: 'Can I have a home built to “Lock-up” stage',
                    text: 'The short answer is yes you can. We assist you determine what must be included as part of the contract, and what can be excluded for you to complete was the contracted agreement has been fulfilled. There may be council or contract requirements which are to be negotiated and included by the builder. We offer a Quotation Management service to assist you determine the Builders which are best fit for you. Ask us more about this when we meet.'
                },
                {
                    heading: 'How does the “Turn Key” process work?',
                    text: 'Turn Key is the result of you negotiating to have everything completed by the Builder prior to you being given the keys to your new home. Our “ecosystem approach” to design ensures we cover off on all aspects and inclusions, which result being the most you have to do is book in the removals company.'
                },
            ]
        },
        {
            title: 'Paperwork & Permits',
            image: Paperwork,
            items: [
                {
                    heading: 'Are there different construction permits required for modular homes?',
                    text: 'The same sets of rules and regulations apply to both conventionally built homes and modular homes. The significant benefits of building a modular home are the flexibility of design, the time saved during manufacture, the accuracy of manufacture and consequent reduction in waste. Combined, the result is a better product for you and a better outcome for the environment.'
                },
                {
                    heading: 'What State and Local Government permits are required and who arranges them?',
                    text: 'All new “habitable” dwellings require building permits and this includes all new homes, regardless of where they are built. Development Approval (DA) and Building Permit (BP) are the two most common permits, both of which are administered by Local Councils. Broadly speaking a DA focuses on ensuring that the proposed dwelling or other building complies with all the rules and regulations that are not the National Building Code of Australia. This includes but is not limited to state government planning policies, local government planning policies and policies which other jurisdictions have in place that impact on your site – noise abatement because your site is beside an airport or BAL requirements because your site is within a designated bushfire zone. On the other hand, the BP focuses on ensuring that the proposed dwelling or building complies with the National Building Code of Australia through its inclusions and method of construction. Development Approval occurs prior to the Building Permit. As your designers we inform you of the design requirements for your site in terms of achieving a development approval. Under your instruction we either complete a compliant design or a design which requires justification via the use of the policy instructions. We are happy to complete all the paperwork for the DA and then lodge it to Council. Generally we would suggest that the BP is lodge by your chosen Builder, as builders often have their own details and construction methods which they employ across all homes and building they construct, since the National Building Code of Australia allows for “many ways to achieve the same outcome”.'
                },
                {
                    heading: 'What role does the Engineer play in my project?',
                    text: 'Involvement of an Engineer is crucial to your project in a number of ways. Initially they conduct a soil test to determine what is in the ground. This information is used to assist with the design of the foundations and footings. Without the soil test no accurate quotes for your site works can be provided. Later your builder will engage an Engineer to ensure that the construction as proposed meets all engineering standards. They will produce a set of documents (the engineering drawings) which will accompany the drafted working drawings as part of the Building Permit pack.'
                },
                {
                    heading: 'What is an Energy rating?',
                    text: 'An Energy Rating is a calculation of the efficiency of your home, in terms or energy consumption for heating and cooling. All new dwellings must have a minimum 6-star energy rating. Energy ratings on homes range from 1 to 10, with 10 being the most efficient. An approved energy rating consultant calculates the rating based on a number of factors including size, building orientation, insulation, shading, windows, and energy efficient lighting. With good design it is generally possible to achieve 7 stars, however orientation of the design relative to North may impact this.	'
                },
                {
                    heading: 'What is a BAL rating?',
                    text: 'If you’re building a new home in many parts Australia, it’s highly likely a BAL (Bushfire Attack Level) report will be required to establish a rating. The six BAL levels range from “Low” to “FZ” or flame zone. A BAL report is produced by a qualified Engineer or Bushfire Assessor. They base their BAL result of a variety of factors including slope of the site, the type of vegetation surrounding the property and how close this vegetation is to your home. The report rating will influence what is required when it’s time to begin manufacturing your new dwelling – if the BAL is over 12.5 there will be some requirements relating to materials used. We generally design homes to suit a BAL 12.5 rating. Should the rating be higher, upgrades can be added to meet the BAL rating for your site. A great site to find out more about building in bushfire prone areas is: http://www.planning.vic.gov.au/bushfire-protection/building-in-bushfire-prone-areas'
                },
                {
                    heading: 'What is a Bushfire Management Plan?',
                    text: 'Each State has a similar perspective on a Bushfire Management Plan (BMP), however these vary in terms of the requirements for the plan and what must be met if you are building in a bushfire prone location. Broadly speaking a BMP is a comprehensive document that sets out short, medium and long term management strategies for bushfire hazards. It provides recommendations and strategies to help reduce your indication Bushfire Attack Level (BAL) rating as well as providing precautions to safeguard the property in case of a bushfire. It should be noted that a BMP is applicable for the life of the development.The following is taken directly from the Country Fire Authority – Victoria which currently has one of the most advanced insight into bushfire. It clearly encapsulates the intent of a BMP. “A bushfire management plan shows all of the bushfire protection measures that will be implemented as part of a development to reduce the risk from bushfire to an acceptable level. Most applications where the proposal must satisfy the bushfire planning controls will include a BMP as part of the information that is submitted to Council for assessment. This plan will be endorsed and form part of the planning permit.”'
                },
                {
                    heading: 'Can Modular Homes be built in BAL 40 and Flame zones?',
                    text: 'BAL 40 construction levels are readily achievable with modular homes, by upgrading to suitable materials which are compliant with the provisions of the Australian Standard for construction of buildings in bushfire prone areas, AS 3959. Modular homes can be designed for “Flame Zone” sites, however the big challenge is the external cladding. The Australian Standard calls for external cladding made of a non-combustible material (e.g., masonry, brick veneer, mud brick, aerated concrete, concrete) or a product with a fire rating with a FRL of 30/30/30. This does limit the products we can use when building in a modular form, however we have a variety of design solutions available. Ask us more about this when we meet.'
                },
            ]
        },
        {
            title: 'The Timeframe',
            image: Time,
            items: [
                {
                    heading: 'How long does it take to build a modular home?',
                    text: 'A modular home is constructed (or manufactured) off-site in a factory. The timeframe is measured in days and weeks. From being manufacture to you getting the front door keys will generally be in 2 to 4 months, depending on the size of your home and what you have contracted the builder to complete. The actual installation of your home onto its foundations will generally take between one and three days, depending on its’ size and the weather conditions of the day. Finishing all the detailed work which cannot be completed at the factory will generally be completed within a few days to a couple of weeks, again dependent on what you have contracted your builder to complete.'
                },
                {
                    heading: 'Is the Design process quick?',
                    text: 'The complexity of your requirements impacts directly on the design process. These days your plans can be completed in 2D or 3D. And there is the option of having computer generated images completed – generally called “Renders”. These may just be of the front of your home, however they may include internal images of your kitchen or main living area. Having a video walk-through created is also available, so you can have a virtual tour of your homer before it has left the “drawing board”. From taking the design brief to having the floor plan and elevations competed generally included 2 or 3 revisions.'
                },
                {
                    heading: 'Does Council take a long time to issue their approval?',
                    text: 'Councils are provided a statutory timeframe to issue a Development Approval, should this be required, which is 60 days if no consultation is required and 90 days if consultation is required. Issuance of a Building Permit, which is always required, is 10 days, based on Council receiving a fully certified Building Permit pack. Certification is done privately between the Builder and the qualified Certifier and generally takes about a week to complete. So about 3 weeks total from when your Builder is ready to get a Building Permit.'
                },
             
            ]
        },
        {
            title: 'Our Planet',
            image: Planet,
            items: [
                {
                    heading: 'Sustainability is important to me. How do I achieve it?',
                    text: 'Sustainability is at the core of planet A design & living ethos. We advocate that every little bit helps, however are pragmatic enough to understand the world is a complex place. As part of the design brief we explore what sustainability means to you and what we can achieve to reach your sustainability goals.  Just deciding to “Go Modular” is a significant step in terms of the reduced impact the building of a new dwelling will have on the environment. We design in everyday affordable materials and products to improve sustainability and suggest upgrades which further enhance the benefits to you and the environment.'
                },
                {
                    heading: 'How does design effect environmental performance?',
                    text: 'The environmental performance of any building is influenced by a number of factors, the primary ones being its’ orientation relative to north (in the northern hemisphere is it relative to south), location of particular rooms within the home relative to north, the materials used, window size & location and insulation. A well designed home which employs “passive house” principles has the capacity to stay warm in winter and cool in summer with little or no additional mechanical heating/cooling.'
                },
                {
                    heading: 'What is a carbon footprint and why is it important?',
                    text: 'In terms of a house, the carbon footprint is the sum of all greenhouse gas emissions induced from the construction of the house, as measured in CO2 equivalents. Therefore what your home is made from and where those materials are produced impact on the carbon footprint. However this is only part of the story. Your house has a carbon footprint which usually continues to grow as it is lived in for decades to come. This is important because a house which needs little or no heating/cooling, for instance, will have a much smaller carbon footprint than one which needs a lot of heating/cooling. Therefore both the design and the materials used are vitally important in reducing CO2 emissions. We strongly advocate the use of timber in the construction of your home and have extensive design expertise for this. And as we progress to documenting what is to be included with your home, we suggest products which have lower carbon footprints wherever possible.'
                },
                {
                    heading: 'What is carbon offset?',
                    text: 'Carbon offset is the term used to highlight an activity which either, prevents, reduces or removes greenhouse gas emissions (CO2 being the one we all know of). Constructing renewable energy plants to replace coal fired plants and improving insulation within a building are two examples of carbon offset. Your decision to extensively use timber in the construction of your new home is a carbon offset as it takes far less energy to turn sustainably produced trees into lumber than it does to turn clay into a brick. And the carbon captured by the tree stays “locked up” in the timber in your home. '
                }
            ]
        },
    ]

    const Dropdowns = (item) => {
        return (
            item.items.map(dropdowns => (
                <Accordion   allowToggle={true} allowMultiple={true}>  
                    <AccordionItem padding='10px'>
                        <h2>
                        <AccordionButton _expanded={{textDecoration: 'underline', fontStyle:'italic'}}>
                            <Box flex="1" textAlign="left"
                            >
                            {dropdowns.heading}
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                        </h2>
                        <AccordionPanel pb={4}>
                        
                        {dropdowns.text}
                        </AccordionPanel>
                    </AccordionItem>
                </Accordion>
            ))
        )

    }

    return (
        <>
        <Flex width='100%' wrap='wrap' alignContent='center' justifyContent='center'>
            <Flex width='60%' wrap='wrap' alignContent='center' justifyContent='center'>

                
            </Flex>
        </Flex>
        <Flex flexWrap='wrap' justifyContent='center' >
            <Box width='65%'>
                <Text textAlign='center' fontSize='40px' fontWeight='900' paddingTop='5%'>FAQ.</Text>
                <Divider borderColor='black' height='1px' bg='black'/>

                <Text textAlign='center' fontSize='19px' paddingTop='50px'>Making decisions about building a new home is an exciting time. Asking one question can sometimes result in an answer as well as more
                    questions. We have simplified this for you by listing the most common questions and including short uncomplicated answers. All the
                    questions and answers provided to you below are of a general nature only and therefore may not be applicable to your circumstances or
                    your project site.<br></br><br></br> Therefore you should not rely on them as the exclusive basis for making any decisions. They should be considered as part of
                    your information gathering process only and you should consult with specialists in each field to obtain specific information about your

                    project and your site. Please feel free to contact us to discuss further.
                </Text>
            </Box>    
            {faqGroups.map(item => (
                <>
                    <Flex  maxW='65%' flexDirection={{base: 'column', md: 'row'}}>

                        <Flex paddingRight='10%' flexDirection='column' className='test' width='100%'>
                            <FadeIn delay='200'>

                            <Text fontSize='20px' fontWeight='700' paddingTop='9%' paddingBottom='2%'>{item.title}</Text>
                            {Dropdowns(item)}
                            </FadeIn>

                        </Flex>
                        <Flex width='100%' position='relative' paddingTop={{base: '10%', md: '5%'}} >
                            <FadeIn delay='400'>
                            <Image objectFit='cover' width='1500px' height='300px'  alignSelf='center' src={item.image}></Image>
                            </FadeIn>
                        </Flex>

                    </Flex>

                </>
            ))}
        
        </Flex>
        
      
        <Box height='200px'></Box>
        </>
    )
}
