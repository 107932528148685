import React from 'react'
import { IconButton, Flex, Center, Grid, Box, Text, Image, HStack } from '@chakra-ui/react'
import { AiOutlineInstagram, AiOutlineFacebook, AiOutlineMail, AiOutlinePhone, AiOutlineLinkedin } from 'react-icons/ai';
import { FaPinterestP } from 'react-icons/fa';
import { RiUnsplashFill } from 'react-icons/ri';

import logo from '../../Assets/images/Navbar/Logo-EcoCompactHomes-WhiteBackground-Plain-V1.0.jpg'
import { Link } from '@chakra-ui/react';

import { Link as RouterLink } from 'react-router-dom'
type footerLinks = {
    title: string;
    link: string;
}

const websiteItems: Array<footerLinks> = [
    { title: "Privacy Policy", link: '/privacy-policy/' },
    { title: "Terms & Conditions", link: '/termsandconditions/' },
]

const learnItem: Array<footerLinks> = [
    { title: "Design to Completion", link: '/process/design' },
    { title: "Quote & Project Management", link: '/process/management' },
    { title: "Sub-division Management", link: '/process/management-sub' }

]

const usefulItem: Array<footerLinks> = [
    { title: "Blog ", link: '/blog' },
    { title: "Useful Links", link: '/useful-links' },
    { title: "News ", link: '/blog' },

]

export default function Footer() {
    return (
        <Flex position='relative' bottom='0' left='0' backgroundColor='#393d40' zIndex='9999' width='100vw' marginTop='auto' direction='column' flexShrink={0}>
            <Center paddingTop='50px' paddingBottom='80px'>


            </Center>
            <Grid width='100%'
                templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(3, 1fr)' }}
                gap={{ base: '20px', md: '0px' }} paddingBottom='125px'>

                <Box textAlign='center' paddingTop='5px' color='white'  >
                    <Text fontWeight='600' fontSize='lg'>Website Information</Text>
                    {websiteItems.map(item => (
                        <RouterLink to={item.link}>
                            <Text paddingTop='8px' fontWeight='400' fontSize={{ base: 'md', md: 'sm' }}>{item.title}</Text>
                        </RouterLink>
                    ))}
                    <Text fontStyle='italic' paddingTop='8px' fontWeight='400' fontSize={{ base: 'md', md: 'sm' }}>© 2021 planet A designs Pty Ltd. All rights reserved. </Text>

                </Box>

                <Box textAlign='center' paddingTop='5px' color='white'  >
                    <HStack justifyContent='center'>

                        <Link href='https://www.instagram.com/planetAdesignn'>
                            <IconButton
                                colorScheme="teal"
                                aria-label="Call Sage"
                                fontSize="20px"
                                icon={<AiOutlineInstagram />}
                            />
                        </Link>
                        <Link href='https://www.facebook.com/planetAdesignn'>

                            <IconButton
                                colorScheme="teal"
                                aria-label="Call Sage"
                                fontSize="20px"
                                icon={<AiOutlineFacebook />}
                            />
                        </Link>
                        <Link href='http://www.pinterest.com/planetAdesignn'>
                            <IconButton
                                colorScheme="teal"
                                aria-label="Call Sage"
                                fontSize="20px"
                                icon={<FaPinterestP />}
                            />
                        </Link>
                        <Link href='https://unsplash.com/@planetadesign'>
                            <IconButton
                                colorScheme="teal"
                                aria-label="Call Sage"
                                fontSize="20px"
                                icon={<RiUnsplashFill />}
                            />
                        </Link>
                        <Link href='https://www.linkedin.com/in/planetAdesignandliving'>
                            <IconButton
                                colorScheme="teal"
                                aria-label="Call Sage"
                                fontSize="20px"
                                icon={<AiOutlineLinkedin />}
                            />
                        </Link>

                        <Link paddingLeft='50px' href='mailto:thedrum@planetadesign.com.au'>
                            <IconButton
                                colorScheme="teal"
                                aria-label="Call Sage"
                                fontSize="20px"
                                icon={<AiOutlineMail />}
                            />
                        </Link>

                        <Link href='tel:0404834844'>
                            <IconButton
                                colorScheme="teal"
                                aria-label="Call Sage"
                                fontSize="20px"
                                icon={<AiOutlinePhone />}
                            />
                        </Link>


                    </HStack>

                </Box>


                <Box textAlign='center' paddingTop='5px' color='white' >
                    <Text fontWeight='600' fontSize='lg'>Useful Resources</Text>
                    {usefulItem.map(item => (
                        <RouterLink to={item.link}>
                            <Text paddingTop='8px' fontWeight='400' fontSize={{ base: 'md', md: 'sm' }}>{item.title}</Text>
                        </RouterLink>
                    ))}

                </Box>
            </Grid >
        </Flex >
    )
}
