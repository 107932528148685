import { Box, Container, Image, SimpleGrid, Text } from '@chakra-ui/react'
import React from 'react'
import ian from '../Assets/images/BridgetownArea.png'

export default function AboutUs() {
    return (
        <Container paddingTop={{base: '30px', md:'100px'}} centerContent>
            <Text fontSize='4xl' paddingTop='20%'>About us</Text>
            <Text>
                Something about the company here
            </Text>

            <SimpleGrid columns={{base: '1', md: '2'}} centerContent width='80vw' paddingTop='15%' spacingX='0px'>
                <Box textAlign='right' >
                    <Image height='35vh' src={ian}></Image>
                </Box>
                <Box  textAlign='center' paddingTop='15%' >
                    <Text paddingTop='25px' fontSize='xl'>Ian Meyer</Text>
                    <Text paddingTop='25px'>something something</Text>
                </Box>
                
            </SimpleGrid>
        </Container>
    )
}
