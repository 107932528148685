import { BookOutlined, EditOutlined, TeamOutlined } from "@ant-design/icons";
import { Box, Divider, Flex, Text } from "@chakra-ui/react";
import React from "react";
import { BrowserView, isMobile } from "react-device-detect";
import ProcessBubbles from "../../Components/processes/process-bubbles";
import ProcessPanel from "../../Components/processes/process-panel";
import ProcessBar from "../../Components/processes/processes-bar";

//images
import Lots from "../../Assets/images/Processes/subdiv-management/11-TheLots-01.jpg";
import Application from "../../Assets/images/Processes/subdiv-management/12-TheApplication-01.jpg";
import Conditions from "../../Assets/images/Processes/subdiv-management/13-TheConditions-01.jpg";
import Titles from "../../Assets/images/Processes/subdiv-management/14-TheTitles-01.jpg";

export default function ProcessSubDivManagement() {
  let test = [
    {
      title: "The Lots",
      component: (
        <TeamOutlined
          style={{ fontSize: "3vw", paddingTop: "20%", color: "white" }}
        />
      ),
    },
    {
      title: "The Application",
      component: (
        <BookOutlined
          style={{ fontSize: "3vw", paddingTop: "20%", color: "white" }}
        />
      ),
    },
    {
      title: "The Conditions",
      component: (
        <EditOutlined
          style={{ fontSize: "3vw", paddingTop: "20%", color: "white" }}
        />
      ),
    },
    {
      title: "The Titles",
      component: (
        <BookOutlined
          style={{ fontSize: "3vw", paddingTop: "20%", color: "white" }}
        />
      ),
    },
  ];

  const color = "gray.700";
  return (
    <>
      <Flex
        flexWrap="wrap"
        height={isMobile ? "150vh" : "90vh"}
        justifyContent="center"
      >
        <Flex flexWrap="wrap" width="100%" justifyContent="center">
          <Box width="65%">
            <Text
              textAlign="center"
              fontSize="40px"
              fontWeight="900"
              paddingTop="5%"
              paddingBottom="5%"
            >
              Sub-Division Management.
            </Text>
            <Divider borderColor="black" height="1px" bg="black" />
          </Box>
        </Flex>
        <ProcessBar />
        <Flex w="100%" justifyContent="center">
          <BrowserView>
            <ProcessBubbles test={test} color={color} width="100%" />
          </BrowserView>
        </Flex>
      </Flex>
      <ProcessPanel
        highContrast={true}
        Image={Lots}
        fontSize="20px"
        color={color}
        Title="The Lots."
        Text="You have a block of land which you wish to sub-divide, either to sell some/all of the new blocks, or to develop and build new homes on. Surveyors can do this, but they have no experience at making the most of the land you have available, so cannot guide you on the benefits of locating the new internal boundaries in different places. They also have no interest in educating you about the conditions that will be imposed by the State Government Planning body, and they are highly unlikely to have much construction knowledge for new homes. Our knowledge and experience at getting the most out of land in terms of lot layout, building of new homes, and our understanding of State Government Planning Authority conditions are a valuable resource for you to tap into. Once we receive the survey, you can expect the best boundary locations to be confirmed within a week or so. We offer this service to anyone, regardless of whether we have been involved in any design or other work earlier. "
      />
      <ProcessPanel
        highContrast={false}
        Image={Application}
        fontSize="20px"
        color={color}
        Title="The Application."
        Text="The Surveyor you engaged to complete the initial Contour & Features survey which assisted us in determining the best location for the Proposed Sub-division boundaries discussed above -The Lots – will generally submit the Sub-division Application on your behalf.
             We can guide you through the process if you wish to reduce the fees charged by the Surveyor, and become “The Applicant”. Once the application is approved the State Planning authority issues a Conditional Approval which is sent directly to the applicant. The timeframe for this is generally 12 weeks. We offer this service to anyone, regardless of whether we have been involved in any design or other work earlier. 
             "
      />
      <ProcessPanel
        highContrast={true}
        Image={Conditions}
        fontSize="20px"
        color={color}
        Title="The Conditions."
        Text="The Sub-division Approval received is conditional. This means that before you can apply for new Certificates of Title you must complete all the works required so that the conditions can be considered “Cleared” or completed. Example conditions could be modify the existing home if it is to be retained, or installing underground power if it is currently overhead power.
             Surveyors are not geared up to provide you with any guidance on how to complete all the works required to have the conditions cleared, however we are. We guide you through every step, explaining what the conditions mean, arranging for quotes to be obtained and confirming the job has been done correctly. Once formal Clearance of the conditions is obtained you are free to obtain new Certificates of Title. We offer this service to anyone, regardless of whether we have been involved in any design or other work earlier. 
             "
      />
      <ProcessPanel
        highContrast={false}
        Image={Titles}
        fontSize="20px"
        color={color}
        Title="The Titles."
        Text="Once the work has been completed in relation to each of the conditions noted in the conditional approval letter, formal clearance of the conditions can be requested. Once each stakeholder has provided their clearance to the State Government Planning Authority a final cross reference occurs.  You are then free to engage your Settlement Agent or Solicitor to obtain your new Certificates of Title. 

We offer this service to anyone, regardless of whether we have been involved in any design or other work earlier."
      />
    </>
  );
}
