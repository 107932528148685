import { Box, Divider, Flex, Image, Stack, Text, Grid } from "@chakra-ui/react";
import React from "react";
import FadeIn from "react-fade-in";
import LazyLoad from "react-lazyload";
import GuestStudio from "../Assets/images/Design-services/GuestStudio-Ratio-16by9.jpg";
import LongHaus from "../Assets/images/Design-services/LongHaus-Ratio-16by9.jpg";
import MiniHausMk2 from "../Assets/images/Design-services/MiniHausMk1-Ratio-16tby9.jpg";

import Independence from "../Assets/images/Benefits/Independance-01.jpg";
import Control from "../Assets/images/Benefits/Control-01.jpg";
import Flexibility from "../Assets/images/Benefits/Flexibility-01.jpg";
import Freedom from "../Assets/images/Benefits/Freedom-01.jpg";
import Choice1 from "../Assets/images/Benefits/01-Choices-02.JPG";
import Choice2 from "../Assets/images/Benefits/01-Choices-5b.jpg";
import Choice3 from "../Assets/images/Benefits/Choices-middle-02.jpg";
import "../Assets/scss/processes.scss";

export default function Benefits() {
  return (
    <>
      <FadeIn transitionDuration="600">
        <Flex textAlign="center" flexWrap="wrap" justifyContent="center">
          <Box width="65%" paddingBottom="0%">
            <FadeIn transitionDuration="6as00">
              <Text
                textAlign="center"
                fontSize="40px"
                fontWeight="900"
                paddingTop="5%"
                paddingBottom="5%"
              >
                Your Benefits.
              </Text>

              <Divider borderColor="black" height="1px" bg="black" />
            </FadeIn>
          </Box>
          <Stack
            wrap="wrap"
            textAlign="center"
            width="65%"
            justifyContent="center"
          >
            <FadeIn>
              <Text
                paddingTop="5%"
                paddingBottom="2%"
                fontWeight="500"
                fontSize="30px"
              >
                Choices
              </Text>
              <Text>
                You are free to choose the design professional who best suits
                your particular scenario and requirements. At all costs it’s
                best to avoid being nabbed by a sales consultant who the builder
                employs. As Independent Designers we carefully listen to you and
                then deliver design outcomes which meet or exceed your
                expectations whilst remaining within the budget parameters you
                set. Choosing what to include in and around your home or project
                can be daunting. We make it easy by answering all your
                questions, offer options and where necessary recommend engaging
                specialist professionals such as interior designers to further
                enhance your project. It’s part of our eco-system approach,
                which insures all bases are covered.
              </Text>

              <Grid
                templateColumns={{ sm: "repeat(1, 1fr)", md: "repeat(3, 1fr)" }}
                gap={4}
              >
                <Image shadow="lg" margin="5px" src={Choice1}></Image>
                <Image shadow="lg" margin="5px" src={Choice3}></Image>
                <Image shadow="lg" margin="5px" src={Choice2}></Image>
              </Grid>
              <Divider
                marginTop="7%"
                marginBottom="7%"
                borderColor="gray"
                height="0.1px"
                bg="gray"
              />
            </FadeIn>

            <LazyLoad height={1000}>
              <Grid
                templateColumns={{ sm: "repeat(1, 1fr)", md: "repeat(2, 1fr)" }}
                gap={4}
              >
                <Box>
                  <Text paddingBottom="20px" fontWeight="500" fontSize="30px">
                    Independence
                  </Text>
                  <Text textAlign="center" width="90%">
                    By choosing us, you are guaranteeing independence from the
                    builder, which is vital to ensuring the best outcome.
                    <br></br>
                    <br></br>We guide and advise you on where to spend and where
                    to save so that the best design result is achieved whilst
                    staying within your budget. Builders tend to guide you in
                    terms of what is best for their budget, which is definitely
                    not in your best interest.
                  </Text>
                </Box>
                <Box>
                  <Image
                    objectFit="cover"
                    width="1300px"
                    height="450px"
                    src={Independence}
                  ></Image>
                </Box>
              </Grid>
              <Divider
                marginTop="7%"
                marginBottom="7%"
                borderColor="gray"
                height="0.1px"
                bg="gray"
              />
            </LazyLoad>

            <LazyLoad height={500}>
              <Grid
                templateColumns={{ sm: "repeat(1, 1fr)", md: "repeat(2, 1fr)" }}
                gap={4}
              >
                <Box>
                  <Image
                    objectFit="cover"
                    width="1300px"
                    height="450px"
                    src={Control}
                  ></Image>
                </Box>
                <Box>
                  <Text paddingBottom="2%" fontWeight="500" fontSize="30px">
                    Control
                  </Text>
                  <Text>
                    We designed your home(s) so the builders are all quoting the
                    same plan – Yours. We can also create a specification and
                    inclusions list with you, so that each builders’ quote is
                    clearly measurable against the other quotes, giving you a
                    clear path for making an informed purchasing decision.
                  </Text>
                </Box>
              </Grid>
              <Divider
                marginTop="7%"
                marginBottom="7%"
                borderColor="gray"
                height="0.1px"
                bg="gray"
              />
            </LazyLoad>

            <LazyLoad height={500}>
              <Flex textAlign="left" wrap="wrap" justifyContent="center">
                <Flex
                  textAlign="center"
                  width="100%"
                  wrap="wrap"
                  justifyContent="center"
                >
                  <Text paddingBottom="2%" fontWeight="500" fontSize="30px">
                    Freedom
                  </Text>
                  <Text>
                    We all have busy lives and taking on a construction project
                    is no small feat. Our range of services and clearly defined
                    processes give you the freedom to choose how to best utilise
                    your time. You can choose to spend more time and have day to
                    day direct or you can engage our services, thus freeing you
                    up to continue for day to day life, knowing you’re in good
                    hands. Our approach allows you and your family to continue
                    living “as normal”.
                    <Text paddingTop="50px" fontWeight="700" fontStyle="italic">
                      Why should stress be a mandatory component of building? In
                      short, it shouldn’t. It should be a choice.
                    </Text>
                  </Text>
                </Flex>
                <Flex paddingTop="50px" width="60%">
                  <Image
                    objectFit="cover"
                    width="1300px"
                    height="450px"
                    src={Freedom}
                  ></Image>
                </Flex>
              </Flex>
              <Divider
                marginTop="7%"
                marginBottom="7%"
                borderColor="gray"
                height="0.1px"
                bg="gray"
              />
            </LazyLoad>

            <LazyLoad height={500}>
              <Grid
                templateColumns={{ sm: "repeat(1, 1fr)", md: "repeat(2, 1fr)" }}
                gap={4}
              >
                <Box>
                  <Image
                    objectFit="cover"
                    width="1300px"
                    height="450px"
                    src={Flexibility}
                  ></Image>
                </Box>
                <Box>
                  <Text paddingBottom="2%" fontWeight="500" fontSize="30px">
                    Flexibility
                  </Text>
                  <Text>
                    Do you have time pressures and really need your home or
                    project completed as fast as is practical, or do you have
                    time on your side? If you are the former, no project will be
                    completed faster than one using modular design and off-site
                    manufacturing – guaranteed. <br></br> <br></br> If you are
                    the latter, then we will complete each stage at the pace
                    that suits you. The design stage can be completed, permits
                    can be lodged and received, however there is no rule that
                    states one must build straight away.
                  </Text>
                </Box>
              </Grid>
              <Divider
                marginTop="7%"
                marginBottom="7%"
                borderColor="gray"
                height="0.1px"
                bg="gray"
              />
            </LazyLoad>
          </Stack>
        </Flex>
      </FadeIn>
    </>
  );
}
