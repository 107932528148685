import { Stack, Radio, Text, Button, ModalHeader, useDisclosure, Modal, ModalOverlay, ModalContent, ModalCloseButton, ModalBody, ModalFooter, FormLabel, Input, FormControl, Textarea, RadioGroup, Checkbox } from '@chakra-ui/react'
import { useState } from 'react'

import EmailFormService from '../../api'

interface Props {
    postInformation: EnquiryInformation
}

interface EnquiryInformation {
    first_name: string,
    last_name: string,
    email: string,
    phone_number: string,
    postcode: number,
    enquiry_reason: string,
    question_notes: string,
    error_message: string
}

export default function ContactModelButton(props: Props) {
    const { isOpen, onOpen, onClose } = useDisclosure()

    const [state, setstate] = useState<EnquiryInformation>({
        first_name: "",
        last_name: "",
        email: "",
        phone_number: "",
        postcode: 6000,
        enquiry_reason: 'DESIGN',
        question_notes: "",
        error_message: ""
    })

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setstate({
            ...state,
            [event.target.name]: value
        })
    }

    //not sure why this typing was being so weird
    const handleQuestionsChange = (event: any) => {
        const value = event.target.value;
        console.log(value)
        setstate({
            ...state,
            [event.target.name]: value
        })
    }


    const handleSubmit = () => {
        //check the required fields
        if (
            state.email === "" ||
            state.phone_number === "" ||
            state.first_name === ""
        ) {
            setstate({ ...state, error_message: "Please fill out the required fields" })
        } else {
            onClose()

            EmailFormService.create(state)
        }



    }

    return (
        <>
            <Button color='white' backgroundColor='#154734' onClick={onOpen}>Coffee or Query ☕ </Button>

            <Modal blockScrollOnMount={true} onClose={onClose} isOpen={isOpen} isCentered>
                <ModalOverlay />
                <ModalContent top='0' height='78vh' overflow='scroll' >
                    <ModalHeader>WISH TO CATCH UP, OR HAVE A QUESTION?</ModalHeader>
                    {state.error_message !== "" && <Text paddingLeft='23px' fontWeight='light' color='red'>{state.error_message}</Text>}
                    <Text paddingLeft='23px' fontWeight='light'>Please provide your details</Text>
                    <ModalCloseButton />
                    <ModalBody pb={6}>
                        <FormControl isRequired>
                            <FormLabel>First Name</FormLabel>
                            <Input placeholder="First name" name='first_name' value={state.first_name} onChange={handleChange} />
                        </FormControl>

                        <FormControl mt={4}>
                            <FormLabel>Last Name</FormLabel>
                            <Input placeholder="Last name" name='last_name' value={state.last_name} onChange={handleChange} />
                        </FormControl>
                        <FormControl isRequired mt={4}>
                            <FormLabel>Email Address</FormLabel>
                            <Input placeholder="example@gmail.com" name='email' value={state.email} onChange={handleChange} />
                        </FormControl>
                        <FormControl isRequired mt={4}>
                            <FormLabel>Phone Number</FormLabel>
                            <Input placeholder="phone" name='phone_number' value={state.phone_number} onChange={handleChange} />
                        </FormControl>
                        <FormControl isRequired mt={4}>
                            <FormLabel>Postcode of your site</FormLabel>
                            <Input placeholder="Postcode" name='postcode' value={state.postcode} onChange={handleChange} />
                        </FormControl>
                        <FormControl isRequired paddingTop='4%'>
                            <FormLabel>What would you like to do</FormLabel>
                            <Stack>
                                <Checkbox size="md" colorScheme="teal" defaultChecked>
                                    Design of a Studio, New Home or Tourist Accommodation
                                </Checkbox>
                                <Checkbox size="md" colorScheme="teal" >
                                    Project Management – I already have plans
                                </Checkbox>
                                <Checkbox size="md" colorScheme="teal" >
                                    Sub-division Management – I would like to sub-divide my land
                                </Checkbox>
                            </Stack>

                            {/* <RadioGroup value={state.enquiry_reason}>
                                <Stack>
                                    <Radio size="sm" value="DESIGN" colorScheme="teal">
                                        Design of a Studio, New Home or Tourist Accommodation
                                    </Radio>
                                    <Radio size="sm" value="PROJECT_MANAGEMENT" colorScheme="teal">
                                        Project Management – I already have plans
                                    </Radio>
                                    <Radio size="sm" value="SUBDIVISION_MANAGEMENT" colorScheme="teal">
                                        Sub-division Management – I would like to sub-divide my land
                                    </Radio>
                                </Stack>
                            </RadioGroup> */}
                        </FormControl>
                        <FormControl mt={4}>
                            <FormLabel>Questions</FormLabel>
                            <Textarea placeholder="" name='question_notes' value={state.question_notes} onChange={handleQuestionsChange} />
                        </FormControl>



                    </ModalBody>

                    <ModalFooter>
                        <Button onClick={handleSubmit} colorScheme="blue" mr={3}>
                            Enquire
                        </Button>
                        <Button onClick={onClose}>Cancel</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}

