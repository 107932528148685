import { Box, Grid, Text } from "@chakra-ui/react";
import React from "react";
import { isMobile } from "react-device-detect";
import FadeIn from "react-fade-in";
import { Link } from "react-router-dom";
import "../../Assets/scss/processes.scss";

export default function ProcessBar(props) {
  return (
    <>
      <Grid
        width={isMobile ? "100%" : "60%"}
        templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(3, 1fr)" }}
        gap={20}
      >
        <FadeIn>
          <Link className="box-item" to="/process/design">
            <Box
              className="box-item"
              backgroundSize="cover"
              backgroundImage="linear-gradient(180deg, rgba(255,0,0,0) 30% , black 120%), url('/Processes/Design-01-Drafting-Hand-01-.jpg')"
              //boxShadow="dark-lg"
              p="6"
              rounded="md"
              borderRadius={isMobile ? "0" : "10"}
              paddingTop="15%"
              textAlign="center"
              w={isMobile ? "100vw" : "100%"}
              h="200px"
              cursor="pointer"
              transition="box-shadow .7s"
              _hover={{
                boxShadow: "dark-lg",
              }}
            >
              <Text
                paddingTop="15%"
                fontWeight="700"
                fontSize="25px"
                color="#e2e8f0"
              >
                Design to Completion
              </Text>
            </Box>
          </Link>
        </FadeIn>

        <FadeIn>
          <Link className="box-item" to="/process/management">
            <Box
              className="box-item"
              backgroundSize="cover"
              backgroundImage="linear-gradient(180deg, rgba(255,0,0,0) 30% , black 120%), url('/Processes/QuoteProjectManagement-03.jpg')"
              //boxShadow="dark-lg"
              p="6"
              rounded="md"
              borderRadius={isMobile ? "0" : "10"}
              paddingTop="15%"
              textAlign="center"
              w={isMobile ? "100vw" : "100%"}
              h="200px"
              cursor="pointer"
              transition="box-shadow .7s"
              _hover={{
                boxShadow: "dark-lg",
              }}
            >
              <Text
                paddingTop="15%"
                textDecoration="none"
                fontWeight="700"
                fontSize="25px"
                color="#e2e8f0"
              >
                Quote & Project Management
              </Text>
            </Box>
          </Link>
        </FadeIn>

        <FadeIn>
          <Link className="box-item" to="/process/management-sub">
            <Box
              className="box-item"
              backgroundSize="cover"
              backgroundImage="linear-gradient(180deg, rgba(255,0,0,0) 30% , black 120%), url('/Processes/Sub-divisionManagement-Main-01.jpg')"
              p="6"
              rounded="md"
              borderRadius={isMobile ? "0" : "10"}
              paddingTop="15%"
              textAlign="center"
              w={isMobile ? "100vw" : "100%"}
              h="200px"
              cursor="pointer"
              transition="box-shadow .7s"
              _hover={{
                boxShadow: "dark-lg",
              }}
            >
              <Text
                paddingTop="15%"
                fontWeight="700"
                fontSize="25px"
                color="#e2e8f0"
              >
                Sub-division Management
              </Text>
            </Box>
          </Link>
        </FadeIn>
      </Grid>
    </>
  );
}
