import { Box, Button, Flex, Grid, GridItem, Text, Tooltip } from '@chakra-ui/react'
import React from 'react'
import { Link } from 'react-router-dom'
import '../../Assets/scss/processes.scss'
import OptionsBar from '../../Components/housedesigns/optionsbar'

export default function OptionsHome() {
    return (
        <> 
            <Text textAlign='center' fontSize='30px' paddingTop='100px'>Your Options</Text>
            <Flex flexWrap='wrap' justifyContent='center' height='40vh'>
                <OptionsBar/>

                <Flex fontSize='20' width='80%' textAlign='center'>
                    <Text paddingTop='10%'>At planet A we are focused on our clients – what are they doing, where are they doing it, when are they doing it and why are they doing it? Understanding our clients’ values and needs are fundamental to all that we do. <br></br> Our Eco-system approach ensures  both you and our environment are winners.</Text>

                </Flex>       
                
            </Flex>
            
            <Flex flexWrap='wrap' justifyContent='center' height='40vh' >
                <Grid paddingBottom='2%' width='70%' templateColumns={{ base: "repeat(1, 1fr)" , md: "repeat(2, 1fr)"  }} gap={100}>
                        <Box textAlign='center'>
                            <Text paddingBottom='2%' fontSize='25'>Design Services</Text>

                            {/* <Text  paddingBottom='2%' fontSize='20' fontWeight='700'>Pre-Designed Homes</Text> */}
                            <Grid templateColumns={{ base: "repeat(1, 1fr)" , md: "repeat(3, 1fr)"  }} gap={1}>
                                <GridItem >
                                    <Tooltip hasArrow placement='top' label="Perfect For small homes" aria-label="A tooltip">
                                        <Text paddingBottom='4%' fontWeight='600'>The Haus Range</Text>
                                    </Tooltip>
                                    <Text paddingBottom='2%' >The Guest Studio</Text>
                                    <Text paddingBottom='2%' >The Mini Haus Mk1</Text>
                                    <Text paddingBottom='2%' >The Mini Haus Mk2</Text>
                                    <Text paddingBottom='2%' >The Mini Haus Mk3</Text>
                                    <Text paddingBottom='2%'>The Small Haus</Text>
                                    <Link to='/home-designs/backyard'>
                                        <Button marginTop='2%' colorScheme="teal" variant="outline">Explore</Button>
                                    </Link>

                                </GridItem>
                                <GridItem >
                                <Tooltip hasArrow placement='top' label="Perfect For small homes" aria-label="A tooltip">
                                    <Text paddingBottom='4%' fontWeight='600'>The Pod Range</Text>
                                </Tooltip>
                                <Text paddingBottom='2%'>Coming Soon...</Text>
                
                                </GridItem>
                                <GridItem>
                                <Tooltip hasArrow placement='top' label="Perfect For small homes" aria-label="A tooltip">
                                        <Text paddingBottom='4%' fontWeight='600'>The Compact Range</Text>
                                </Tooltip>                                    
                                    {/* <Text paddingBottom='2%'>The Narrow House</Text>
                                    <Text paddingBottom='2%'>The Tenner House</Text>
                                    <Text paddingBottom='2%'>The Wide House</Text>
                                    <Text paddingBottom='2%'>The Graphite House</Text>
                                    <Text paddingBottom='2%'>The White House</Text> */}
                                    <Text paddingBottom='2%'>Coming Soon...</Text>

                                    {/* <Link to='/home-designs/backyard'>
                                        <Button marginTop='2%' colorScheme="teal" variant="outline">Explore</Button>
                                    </Link> */}

                                </GridItem>
                            </Grid>
                            <Text paddingTop='5%' fontWeight='700'>Custom Designed Homes</Text>
                            <Text>Our forte – designing just for you. It is an exciting journey which starts with a chat over the phone and a catch-up soon after, whether you are in the country or the city.</Text>
                        </Box>
                        <Box textAlign='center'>
                            <Text paddingBottom='2%' fontSize='25'>Management Services</Text>

                            <Text paddingBottom='2%' fontWeight='700'>Quotation Management</Text>
                            <Text>This service available to clients independently of our design services. We have over 20 years’ experience in residential construction in all construction types, including double clay brick, brick veneer and timber or steel frame construction.</Text>
                            <Button marginBottom='4%' marginTop='2%' marginBottom='2%' colorScheme="teal" variant="outline">Explore</Button>

                            <Text paddingBottom='2%' fontWeight='700'>Project Management</Text>
                            <Text>Our Project Management option provides you with ongoing support during both the Pre-construction phase and the Construction phase of your new build.

                            All too often we find that builders’ clients are not well informed about their rights, and the responsibilities of the builder when it comes to construction.

                            You can select to have our involvement and guidance during the early stages, between accepting a quotation and action on site and/or during the on-site period of your contract. 
                            </Text>
                            <Button marginBottom='4%' marginTop='2%' colorScheme="teal" variant="outline">Explore</Button>
                            <Text paddingBottom='2%' fontWeight='700'>Sub-Division Management</Text>
                            <Text>Our Sub-division Management option makes subdividing your block a breeze. Surveyors are great at what they do, however they have no idea about houses and construction requirements.

                            <br></br><br></br>Step 1 involves having the Surveyors complete the Contour and Features Survey, followed by us working with you to ensure that your proposed boundaries and new lot sizes make the most of the available land, which may involve consulting the local Council Planners. We provide Proposed New Lot information to the Surveyors and the Sub-division Application is submitted to the State Planning Authority for ratification.

                            <br></br><br></br>Step 2 involved us guiding you through Compliance Stage, after the Approval letter is received, by completing online applications to infrastructure providers and arranging for quotations for all works required on site. The result is all conditions set by the State Planning Authority are satisfied and you can proceed to having new Certificates of Title issued.
</Text>
                            <Button marginBottom='4%' marginTop='2%' colorScheme="teal" variant="outline">Explore</Button>
                        </Box>                
                </Grid>
            </Flex>
            

             </>
    )
}
