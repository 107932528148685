import { Text, Flex, ListItem, UnorderedList, Stack, Link, ListIcon, List, HStack, Grid, Divider } from '@chakra-ui/react'
import FadeIn from "react-fade-in";
import TitleHeader from '../Components/common/titleHeader'
import { AiOutlineRight } from 'react-icons/ai';



interface Props {
    postInformation: PostInformation
}

interface PostInformation {
    title: string,
    description: string,
    author: string,
    text: string
}

export default function TermsAndConditions(props: Props) {


    return (

        <FadeIn transitionDuration={600}>

            <TitleHeader title='Terms & Conditions' />
            <Flex justifyContent='center' direction='column' alignItems='center'>

                <Flex flexDirection='column' textAlign='center' alignContent='center' width='35%'>

                    <Text pb='25px' fontSize='24px' fontWeight='700'>Accessing and Using the Website.</Text>
                    <Text pb='15px'>Accessing and using the www.planetAdesign.com.au website means that you agree to and accept to the Terms and Conditions and understand our Privacy Policy. Do not use this website if you do not accept our Terms & Conditions.</Text>
                </Flex>

                <Divider
                    marginTop="2%"
                    marginBottom="2%"
                    borderColor="gray"
                    height="0.1px"
                    bg="gray"
                    w='40%'
                />

                <Flex flexDirection='column' textAlign='center' alignContent='center' width='35%'>

                    <Text pb='25px' fontSize='24px' fontWeight='700'>Disclaimer.</Text>
                    <Text pb='15px'>Although all due care is taken, no liability or responsibility is accepted by Planet A Design & Living for any errors, omissions or discrepancies found within our website. By using www.planetAdesign.com.au you accept that Planet A Design & Living, it’s owners, employees and other stakeholders, are not liable or responsible for any loss or harm, including but not limited to personal and financial loss or harm caused to you, directly or indirectly, or any claims and expenses that you or any other party, related or unrelated to you, incur into the indefinite future from the use of this website, links provided within this website, and/or any services provided to you.</Text>
                </Flex>
                <Divider
                    marginTop="2%"
                    marginBottom="2%"
                    borderColor="gray"
                    height="0.1px"
                    bg="gray"
                    w='40%'
                />
                <Flex flexDirection='column' textAlign='center' alignContent='center' width='35%'>

                    <Text pb='25px' fontSize='24px' fontWeight='700'>Warranty.</Text>
                    <Text pb='15px'>No warranties or guarantees are offered or entered into between you or any other party, related or unrelated to you, and Planet A Design & Living, it’s owners, employees and other stakeholders by your use of the website and/or our services, unless a Specific Agreement is agreed to in writing, with signatures and dates attached. Emails, with or without email signatures, are not considered a specific agreement. A document attached to an email, with signatures and dates, would be a specific agreement.  An electronically signed document produced via a cloud based host would be a specific agreement. An original hard copy document, signed and dated, would be a specific agreement.
                    </Text>
                </Flex>
                <Divider
                    marginTop="2%"
                    marginBottom="2%"
                    borderColor="gray"
                    height="0.1px"
                    bg="gray"
                    w='40%'
                />
                {/* <Flex flexDirection='column' textAlign='center' alignContent='center' width='35%'>

                    <Text pb='25px' fontSize='24px' fontWeight='700'>Copyright and Limitations of Use.</Text>
                    <Text pb='15px'>The content in this website is copyright protected by Planet A Design & Living. All content including photos, general text and blog posts are not to be copied, republished, shared or altered in any form, without our express written permission. Further copyright information can be found at https://www.communications.gov.au/documents/short-guide-copyright .
                        We are generally more than willing to work with you should you have an interest in any of our content – our goal is to avoid the spurious use and abuse of our content and intellectual property.
                        The only exception to the above is sharing our content on social media pages. Sharing our content on Social Media is permitted, however it MUST BE attributed to “@planetAdesignn” or www.planetAdesign.com.au is a manner which clearly allows for due recognition.</Text>
                </Flex>
                <Divider
                    marginTop="2%"
                    marginBottom="2%"
                    borderColor="gray"
                    height="0.1px"
                    bg="gray"
                    w='40%'
                /> */}




            </Flex>

        </FadeIn>


    )

}

