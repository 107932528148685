import { Text, Flex, ListItem, UnorderedList, Stack, Link, ListIcon, List, HStack, Grid, Divider } from '@chakra-ui/react'
import FadeIn from "react-fade-in";
import TitleHeader from '../Components/common/titleHeader'
import { AiOutlineRight } from 'react-icons/ai';



interface Props {
    postInformation: PostInformation
}

interface PostInformation {
    title: string,
    description: string,
    author: string,
    text: string
}

export default function PrivacyPolicy(props: Props) {


    return (

        <FadeIn transitionDuration={600}>

            <TitleHeader title='Privacy Policy' />
            <Flex justifyContent='center' direction='column' alignItems='center'>

                <Flex flexDirection='column' textAlign='center' alignContent='center' width='35%'>

                    <Text pb='25px' fontSize='24px' fontWeight='700'>Your Consent.</Text>
                    <Text pb='15px'>By using our site, you consent to our online privacy policy.</Text>
                </Flex>
                <Divider
                    marginTop="2%"
                    marginBottom="2%"
                    borderColor="gray"
                    height="0.1px"
                    bg="gray"
                    w='40%'
                />

                <Flex flexDirection='column' textAlign='center' alignContent='center' width='35%'>

                    <Text pb='25px' fontSize='24px' fontWeight='700'>Your details and website usage.</Text>
                    <Text pb='15px'>When contacting us your name, email address, phone number & IP number are collected as part of your message along with any other information you have entered.</Text>
                    <Text>We may track the site usage of website visitors. The information collected through services such as google analytics and google adwords is not personally identifiable.  If you would prefer not to have your usage tracked head to the Network Advertising Initiative Opt Out page or the Google Analytics Opt Out Browser add on.</Text>
                </Flex>

                <Divider
                    marginTop="2%"
                    marginBottom="2%"
                    borderColor="gray"
                    height="0.1px"
                    bg="gray"
                    w='40%'
                />

                <Flex flexDirection='column' textAlign='center' alignContent='center' width='35%'>

                    <Text pb='25px' fontSize='24px' fontWeight='700'>Your information usage.</Text>
                    <Text pb='15px'>We use the information you provided solely to assist you obtain an outcome which meets your expectations, and for marketing purposes. The information collected will be used to reply to you and further assist you. The information collected is used internally and may be provided to other trusted parties for marketing, advertising, or other uses. It will not be sold or traded to outside parties.</Text>
                </Flex>
                <Divider
                    marginTop="2%"
                    marginBottom="2%"
                    borderColor="gray"
                    height="0.1px"
                    bg="gray"
                    w='40%'
                />


                {/* 
                <Flex flexDirection='column' textAlign='center' alignContent='center' width='35%'>

                    <Text pb='25px' fontSize='24px' fontWeight='700'>Your Cookies, Our Cookies.</Text>
                    <Text pb='15px'>We use cookies. You can block cookies by changing the settings in your browser.</Text>
                </Flex>
                <Divider
                    marginTop="2%"
                    marginBottom="2%"
                    borderColor="gray"
                    height="0.1px"
                    bg="gray"
                    w='40%'
                /> */}

                <Flex flexDirection='column' textAlign='center' alignContent='center' width='35%'>

                    <Text pb='25px' fontSize='24px' fontWeight='700'>Do we disclose any information to outside parties?</Text>
                    <Text pb='15px'>We do not sell, trade, or otherwise transfer to outside parties your personally identifiable information. This does not include trusted third parties who assist us in operating our website, conducting our business, or servicing you, so long as those parties agree to keep this information confidential. We may also release your information when we believe release is appropriate to comply with the law, enforce our site policies, or protect ours or others rights, property, or safety. However, non-personally identifiable visitor information may be provided to other parties for marketing, advertising, or other uses.</Text>
                </Flex>
                <Divider
                    marginTop="2%"
                    marginBottom="2%"
                    borderColor="gray"
                    height="0.1px"
                    bg="gray"
                    w='40%'
                />
                <Flex flexDirection='column' textAlign='center' alignContent='center' width='35%'>

                    <Text pb='25px' fontSize='24px' fontWeight='700'>Our Privacy Policy.</Text>
                    <Text pb='15px'>If we change our privacy policy, this page changes. We do not inform you when our Privacy Policy changes.</Text>
                </Flex>
                <Divider
                    marginTop="2%"
                    marginBottom="2%"
                    borderColor="gray"
                    height="0.1px"
                    bg="gray"
                    w='40%'
                />
                <Flex flexDirection='column' textAlign='center' alignContent='center' width='35%'>

                    <Text pb='25px' fontSize='24px' fontWeight='700'>Our Contact Address.</Text>
                    <Text pb='15px'>If there are any questions regarding this privacy policy you may contact us at thedrum@planetadesign.com.au</Text>
                </Flex>
                <Divider
                    marginTop="2%"
                    marginBottom="2%"
                    borderColor="gray"
                    height="0.1px"
                    bg="gray"
                    w='40%'
                />


            </Flex>

        </FadeIn>


    )

}

